import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';

import Box from '../../Atoms/Box';
import Button from '../../Atoms/Buttons/Button';
import Loader from '../../Atoms/Loader';

const translateMessages = defineMessages({
	hello: {
		id: 'customerInfo.hello',
		defaultMessage: 'Olá'
	},
	notMe: {
		id: 'customerInfo.notMe',
		defaultMessage: 'Não sou eu'
	}
});

/* eslint-disable camelcase */
const CustomerInfo = ({ delay, intl }) => {
	const [clearing, setClearing] = useState(false);
	const { formatMessage } = intl;
	const { customer = {} } = window.omzVish;
	const { fields = {} } = customer;
	const { main_identifier = '' } = fields;

	if (!main_identifier) return null;

	const clearUser = () => {
		setClearing(true);
		window.omzVish.updateCustomer({}, false);
	};

	return (
		<Box className="CustomerInfo" delay={delay}>
			<p className="CustomerInfo__name">
				{formatMessage(translateMessages.hello)}
				,
				<strong>
					{` ${main_identifier}.`}
				</strong>
			</p>
			<Button className="CustomerInfo__button" onClick={clearUser}>
				{clearing ? <Loader fill="white" /> : formatMessage(translateMessages.notMe)}
			</Button>
		</Box>
	);
};
/* eslint-enable camelcase */

CustomerInfo.propTypes = {
	delay: PropTypes.number,
	intl: PropTypes.shape().isRequired
};

CustomerInfo.defaultProps = {
	delay: 100
};

export default injectIntl(CustomerInfo);
