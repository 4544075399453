import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './index.css';
import ZenviaInfo from '../../Atoms/ZenviaInfo';
import WelcomeMessage from '../../Molecules/WelcomeMessage';
import Minimize from '../../Molecules/Minimize';
import StartInteraction from '../../Organisms/StartInteraction';
import CustomerInfo from '../../Organisms/CustomerInfo';
import ImgSvg from '../../Atoms/ImgSvg';

import browserInfo from '../../../shared/browserInfo';

const InitialTemplate = ({ appearance }) => {
	const inittialDelay = 100;
	const { data = {} } = appearance;
	const { jsonAppearance = {}, useUrlCustomer, partnerId } = data;
	const { messages = {} } = jsonAppearance;

	return (
		<>
			<span className="InitialTemplate__background">
				<ImgSvg name="background" />
			</span>
			{browserInfo().mobile && <Minimize className="InitialTemplate__minimize" />}
			<div className="InitialTemplate">
				<div className="InitialTemplate__content">
					<div className="InitialTemplate__welcome">
						<WelcomeMessage
							header={messages['header-main']}
							paragraph={messages['message-inv-welcome']}
						/>
					</div>
					<StartInteraction delay={inittialDelay} />
					{!useUrlCustomer && <CustomerInfo delay={inittialDelay + 300} />}
				</div>
				<div className="InitialTemplate__omnizeInfo">
					{!partnerId && <ZenviaInfo />}
				</div>
			</div>
		</>
	);
};

InitialTemplate.propTypes = {
	appearance: PropTypes.shape({
		data: PropTypes.shape({
			jsonAppearance: PropTypes.shape({
				messages: PropTypes.shape({
					'header-main': PropTypes.string,
					'message-inv-welcome': PropTypes.string
				})
			}),
			inOfficeHours: PropTypes.bool,
			useUrlCustomer: false,
			partnerId: PropTypes.number
		})
	}).isRequired
};

const mapStateToProps = (state) => ({
	appearance: state.appearance
});

export default connect(mapStateToProps)(InitialTemplate);
