import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

import Controls from './Controls';
import './index.css';

class Call extends Component {
	remoteVideoRef = React.createRef()

	state = {
		enabledMic: true,
		enabledVideo: true
	}

	componentDidMount() {
		window.omzVish.startVideoConnection(true, () => {
			window.omzVish.connectAgentVideo();
		});
	}

	onToggleVideo = () => {
		const { stream } = window.omzVish;
		stream.getVideoTracks()[0].enabled = !stream.getVideoTracks()[0].enabled;
		this.setState({ enabledVideo: stream.getVideoTracks()[0].enabled });
	};

	onToggleMic = () => {
		const { stream } = window.omzVish;
		stream.getAudioTracks()[0].enabled = !stream.getTracks()[0].enabled;
		this.setState({ enabledMic: stream.getAudioTracks()[0].enabled });
	};

	onCloseCall = () => {
		const { onCloseCall, interactionHash } = this.props;
		window.omzVish.closeAgentVideo();
		onCloseCall(interactionHash);
	}

	onExpandRemoteVideo = () => {
		this.remoteVideoRef.current.requestFullscreen();
	}

	render() {
		const { enabledMic, enabledVideo } = this.state;
		const { expanded } = this.props;
		const classes = ['Call__cam__video'];
		if (expanded) classes.push('Call__cam__video--expanded');

		return (
			<div className="Call">
				<div className="Call__cam">
					<video className={classes.join(' ')} id="remoteVideo" playsInline ref={this.remoteVideoRef}>
						<track kind="captions" />
					</video>
					<div className="Call__cam--mini">
						<video id="localVideo" playsInline muted="muted">
							<track kind="captions" />
						</video>
					</div>
					<div className="Call__controls">
						<Controls
							enabledVideo={enabledVideo}
							enabledMic={enabledMic}
							onExpand={this.onExpandRemoteVideo}
							onToggleVideo={this.onToggleVideo}
							onToggleMic={this.onToggleMic}
							onEnd={this.onCloseCall}
						/>
					</div>
				</div>
			</div>
		);
	}
}

Call.propTypes = {
	expanded: PropTypes.bool,
	interactionHash: PropTypes.string,
	onCloseCall: PropTypes.func.isRequired
};

Call.defaultProps = {
	expanded: false,
	interactionHash: ''
};

const mapStateToProps = (state) => ({
	interactionHash: state.interaction.currentInteractionHash
});

const mapActionsToProps = (dispatch) => ({
	onCloseCall: (interactionHash) => dispatch(actions.closeVideo(interactionHash))
});

export default connect(mapStateToProps, mapActionsToProps)(Call);
