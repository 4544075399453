import * as actionTypes from './actionTypes';
import { findGetParameter } from '../../shared/utility';

export const addMessageSuccess = (addMessage) => (
	{ type: actionTypes.ADD_MESSAGE_SUCCESS, addMessage }
);
export const newMessageSuccess = (newMessage) => (
	{ type: actionTypes.NEW_MESSAGE_SUCCESS, payload: newMessage }
);
export const newMessage = (message, agentInfo) => {
	const newMsg = { ...message };
	const { name, photo } = agentInfo;
	newMsg.agentPhoto = photo;
	newMsg.agentName = name;
	return (dispatch) => {
		dispatch(newMessageSuccess(newMsg));
	};
};

export const updateMessageSuccess = (message) => (
	{ type: actionTypes.UPDATE_MESSAGE_SUCCESS, payload: message }
);
export const updateMessage = (message) => (dispatch) => {
	dispatch(updateMessageSuccess(message));
};

export const sendMessageSuccess = (message) => (
	{ type: actionTypes.SEND_MESSAGE_SUCCESS, message }
);
export const sendMessage = (
	value,
	sendToVish = true,
	botAnswerId = null,
	choice = null,
	contentType = 'text'
) => (dispatch) => {
	const message = {
		content: value,
		contentType,
		createdAt: new Date().toISOString(),
		tempId: new Date().getTime()
	};

	if (botAnswerId && choice) {
		message.botAnswerId = botAnswerId;
		message.choice = choice;
	}

	if (sendToVish) window.omzVish.newMessage(message);
	dispatch(sendMessageSuccess(message));
};

export const clearMessagesSuccess = () => ({ type: actionTypes.CLEAR_MESSAGES_SUCCESS });
export const clearMessages = () => (dispatch) => {
	dispatch(clearMessagesSuccess());
};

export const loadMessagesBegin = () => ({
	type: actionTypes.LOAD_MESSAGES_BEGIN
});
export const loadMessagesSuccess = (messages) => ({
	type: actionTypes.LOAD_MESSAGES_SUCCESS,
	payload: messages
});
export const loadMessagesFailed = () => ({
	type: actionTypes.LOAD_MESSAGES_FAIL
});
export const loadMessages = (interactionHash) => (
	(dispatch) => {
		dispatch(loadMessagesBegin());
		return fetch(`${process.env.OMZ_API_HTTPS}/external/messages/${interactionHash}`, { method: 'GET' })
			.then((res) => res.json())
			.then((json) => {
				if (json.status === 200) {
					dispatch(loadMessagesSuccess(json.data));
				} else {
					dispatch(loadMessagesFailed(json.errorMessage));
				}
			});
	}
);

export const fakeAgentMessage = (message, type = 'text') => ({
	type: actionTypes.NEW_MESSAGE_SUCCESS,
	payload: {
		content: message,
		contentType: type,
		createdAt: new Date(),
		tempId: new Date().getTime()
	}
});

export const departmentMessage = (message, departments) => ({
	type: actionTypes.NEW_MESSAGE_SUCCESS,
	payload: {
		content: {
			message,
			options: departments.map((department) => {
				const { name, id } = department;

				return {
					label: name,
					value: id
				};
			})
		},
		contentType: 'select_department',
		createdAt: new Date(),
		tempId: new Date().getTime()
	}
});

export const attendanceMessages = (appearance) => (
	(dispatch) => {
		const { messages } = appearance.jsonAppearance;
		setTimeout(() => {
			dispatch(fakeAgentMessage(messages['message-inv-welcome'], 'welcome'));
		}, 200);
	}
);

export const addDepartmentSelect = (appearance) => (
	(dispatch) => {
		const { jsonAppearance, listDepartmentWrapper } = appearance;
		const { messages } = jsonAppearance;
		dispatch(departmentMessage(messages['message-select-department'], listDepartmentWrapper));
	}
);

export const formMessage = (title, fields) => ({
	type: actionTypes.NEW_MESSAGE_SUCCESS,
	payload: {
		content: {
			message: title,
			form: fields
		},
		contentType: 'customer_form',
		createdAt: new Date(),
		tempId: new Date().getTime()
	}
});
export const addCustomerForm = (title, fields) => (
	(dispatch) => {
		dispatch(formMessage(title, fields));
	}
);

export const sendAttachment = ({ formData, fileInfo }) => {
	const accountId = findGetParameter(window.omzVish.url.search, 'id');

	return (dispatch) => (
		fetch(`${process.env.OMZ_API_HTTPS}/external/uploads?accountId=${accountId}`, {
			method: 'POST',
			'Content-type': 'multipart/form-data',
			body: formData
		})
			.then((res) => {
				if (res.ok) return res.json();
				return {};
			}).then((json) => {
				if (json.status === 200) {
					const receivedMessage = JSON.parse(json.files[0]);
					const updatedFileInfo = { ...fileInfo };
					updatedFileInfo.url = receivedMessage.url;

					const formattedMessage = {
						contentType: fileInfo.type,
						createdAt: new Date(),
						tempId: new Date().getTime(),
						url: receivedMessage.url,
						source: 'CLIENT',
						status: 'SENT'
					};

					window.omzVish.newMessage({
						tempId: formattedMessage.tempId,
						content: '',
						url: receivedMessage,
						contentType: fileInfo.type
					});

					dispatch(sendMessageSuccess(formattedMessage));
				}
			})
	);
};
