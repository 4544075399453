import React from 'react';

import Bubble from '../../../Atoms/Bubble';
import './index.css';

const MessageTyping = () => (
	<div className="MessageTyping MessageTyping--client">
		<Bubble isFromAgent>
			<div className="MessageTyping__animation">
				<span className="dot" />
				<span className="dot" />
				<span className="dot" />
			</div>
		</Bubble>
	</div>
);

export default MessageTyping;
