export const updateObject = (oldObject, updatedProperties) => ({
	...oldObject,
	...updatedProperties
});

export const getObjectWithValues = (objectToFilter) => {
	const objectFiltered = {};

	Object.keys(objectToFilter).forEach((key) => {
		const notNull = objectToFilter[key] !== null;
		const notUndefined = objectToFilter[key] !== undefined;
		const notNan = objectToFilter[key] !== NaN;

		if (notNull && notUndefined && notNan) {
			objectFiltered[key] = objectToFilter[key];
		}
	});

	return objectFiltered;
};

export const findGetParameter = (search, parameterName) => {
	let result = null;
	let tmp = [];
	search
		.substr(1)
		.split('&')
		.forEach((item) => {
			tmp = item.split('=');
			if (tmp[0] === parameterName) {
				result = decodeURIComponent(tmp[1]);
			}
		});
	return result;
};

export const getUrlWithProtocol = (server) => {
	if (window.location.protocol === 'https:') {
		switch (server) {
		case 'VISH':
			return process.env.OMZ_VISH_HTTPS;
		case 'API':
			return process.env.OMZ_API_HTTPS;
		case 'VISH_WS':
			return process.env.OMZ_VISH_WSS;
		default:
			return null;
		}
	}

	switch (server) {
	case 'VISH':
		return process.env.OMZ_VISH_HTTP;
	case 'API':
		return process.env.OMZ_API_HTTP;
	case 'VISH_WS':
		return process.env.OMZ_VISH_WS;
	default:
		return null;
	}
};

export const setRequestStatus = (status, error) => {
	switch (status) {
	case 'fail':
		return {
			loading: false,
			error,
			success: false
		};
	case 'success':
		return {
			loading: false,
			error: null,
			success: true
		};
	default:
		return {
			loading: true,
			error: null,
			success: false
		};
	}
};

export const getHoursAndMinutes = (time) => {
	const createdAt = new Date(time);
	const hours = `0${createdAt.getHours()}`.slice(-2);
	const minutes = `0${createdAt.getMinutes()}`.slice(-2);

	return `${hours}:${minutes}`;
};

export const parseMessages = (messageItem) => {
	const {
		id,
		tempId,
		messageId,
		datetime,
		createdAt,
		source,
		message,
		content,
		type,
		contentType,
		state,
		url,
		interactionHash,
		/* eslint-disable-next-line camelcase */
		user_id,
		agentName,
		agentPhoto,
		departments
	} = messageItem;

	return {
		id: id || messageId,
		tempId,
		createdAt: createdAt || datetime,
		source,
		content: content || message,
		contentType: contentType || type,
		state,
		url,
		interactionHash,
		/* eslint-disable-next-line camelcase */
		user_id,
		agentName,
		agentPhoto,
		departments
	};
};

export const getAverageMinutesFromSeconds = (seconds) => Math.round(seconds / 60);

export const getAverageHoursFromMinutes = (minutes) => Math.round(minutes / 60);

export const getFirstAndLastName = (name) => {
	if (name) {
		const names = name.split(' ');
		if (names.length === 1) return `${names[0]}`;
		return `${names[0]} ${names[names.length - 1]}`;
	}

	return '';
};

export const isOnOfficeHours = (officeHours) => {
	if (!officeHours || officeHours.length === 0) return true;
	return officeHours.some((officeHour) => officeHour.status === true);
};

export const departmentHasChannel = (departments, departmentId, channelName) => departments.some(
	(department) => {
		const { id, listChannelWrapper = [] } = department;

		if (id === departmentId) {
			return listChannelWrapper.some((channel) => channel.name === channelName);
		}

		return false;
	}
);

export const fillCustomerValues = (customerFields, fields) => customerFields.map((field) => {
	const fieldWithValue = field;

	fieldWithValue.value = fields[field.name];
	return fieldWithValue;
});
