import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import Button from '../../Atoms/Buttons/Button';
import Backdrop from '../../Atoms/Backdrop';
import AvatarName from '../../Atoms/AvatarName';
import HeadingPrimary from '../../Atoms/Headers/HeadingPrimary';
import RateNps from '../Rates/RateNps';
import RateMood from '../Rates/RateMood';
import RateStar from '../Rates/RateStar';
import './index.css';


const RateModal = ({
	rate,
	options,
	onSelect,
	agent,
	intl
}) => {
	let rateComponent;
	switch (rate) {
	case 'nps':
		rateComponent = <RateNps options={options} onSelect={onSelect} />;
		break;
	case 'star':
		rateComponent = <RateStar options={options} onSelect={onSelect} />;
		break;
	default:
		rateComponent = <RateMood options={options} onSelect={onSelect} />;
		break;
	}

	const { photo } = agent;

	const messages = defineMessages({
		message: {
			id: 'rateModal.message',
			defaultMessage: 'Avalie o meu atendimento'
		},
		skip: {
			id: 'rateModal.skip',
			defaultMessage: 'Pular'
		},
		question: {
			id: 'rateModal.question',
			defaultMessage: 'Você foi bem atendido?'
		}
	});

	const { formatMessage } = intl;

	return (
		<Backdrop>
			<div className="RateModal">
				<div className="RateModal__avatar">
					<AvatarName url={photo} />
				</div>
				<div className="RateModal__info">
					<HeadingPrimary info={formatMessage(messages.question)} />
					<p>{formatMessage(messages.message)}</p>
				</div>
				{ rateComponent }
				<Button fill="transparent" onClick={() => onSelect()}>{formatMessage(messages.skip)}</Button>
			</div>
		</Backdrop>
	);
};

RateModal.propTypes = {
	rate: PropTypes.oneOf(['nps', 'star', 'mood']).isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string
	})).isRequired,
	onSelect: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		photo: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

export default injectIntl(RateModal);
