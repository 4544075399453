import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutOfServicePeriod from '../../Atoms/OutOfServicePeriod';
import Button from '../../Atoms/Buttons/Button';
import InfiniteList from '../InfiniteList';
import { isOnOfficeHours } from '../../../shared/utility';
import './index.css';

class SelectDepartment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showOfficeHours: null
		};
	}

	showMore = (e, value) => {
		const { showOfficeHours } = this.state;
		this.setState({ showOfficeHours: showOfficeHours === value ? null : value });
	}

	render() {
		const { choices, onSelectChoice } = this.props;
		if (choices.length === 1) onSelectChoice(choices[0].value);

		return (
			<>
				<div className="SelectDepartment">
					<div className="SelectDepartmentList">
						<InfiniteList autoScrollDown>
							{choices.map((choice) => {
								const {
									value,
									label,
									officeHours,
									outOfServiceMessage,
									holiday
								} = choice;
								const { showOfficeHours } = this.state;
								const active = holiday ? false : isOnOfficeHours(officeHours);
								return (
									<div key={value} className="SelectDepartment__choice">
										<input
											type="radio"
											name="value"
											id={value}
											className="SelectDepartment__choice__input"
											onChange={() => onSelectChoice(value)}
											value={value}
										/>
										<label htmlFor={value} className="SelectDepartment__choice__label">
											<>
												<span className={`SelectDepartment__choice__active${active ? '__on' : ''}`} />
												{label}
												{!active ? (
													<>
														<span className="SelectDepartment__choice__message">{outOfServiceMessage}</span>
														{officeHours.length !== 0 ? (
															<Button onClick={(e) => this.showMore(e, value)} fill="transparent" className="SelectDepartment__choice__button">
																<span className={`SelectDepartment__choice__${showOfficeHours === value ? 'less' : 'more'}`} />
															</Button>
														) : ''}
													</>
												) : ''}
											</>
										</label>
										{officeHours.length !== 0 ? (
											<span className={`SelectDepartment__choice__officeHour${showOfficeHours === value ? '' : '__hidden'}`}>
												<OutOfServicePeriod officeHours={officeHours} />
											</span>
										) : ''}
									</div>
								);
							})}
						</InfiniteList>
					</div>
				</div>
			</>
		);
	}
}

SelectDepartment.propTypes = {
	choices: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string,
		officeHours: PropTypes.array,
		outOfServiceMessage: PropTypes.string
	})),
	onSelectChoice: PropTypes.func
};

SelectDepartment.defaultProps = {
	choices: [],
	onSelectChoice: () => {}
};

export default SelectDepartment;
