import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import ImgSvg from '../../../Atoms/ImgSvg';

const File = ({ isFromAgent }) => {
	const infoClasses = ['File__info'];
	if (!isFromAgent) infoClasses.push('File__info--client');

	return (
		<div className="File">
			<div className={infoClasses.join(' ')}>
				<ImgSvg name="attach" />
			</div>
		</div>
	);
};

File.propTypes = {
	isFromAgent: PropTypes.bool.isRequired
};

export default File;
