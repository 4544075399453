import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';

import InputText from '../../Atoms/Inputs/InputText';
import Button from '../../Atoms/Buttons/Button';

import './index.css';

export class MissingAcocuntId extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ''
		};
	}

	onAddAccountId = () => {
		const { history } = this.props;
		history.push('/?reloadAppearance=true');
	}

	render() {
		const { intl } = this.props;
		const { formatMessage } = intl;
		const { value } = this.state;

		const messages = defineMessages({
			error: {
				id: 'missingAccountId.error',
				defaultMessage: 'Erro!'
			},
			message: {
				id: 'missingAccountId.message',
				defaultMessage: 'Não foi informado o id da conta, é necessário passar via parametro na url.'
			},
			save: {
				id: 'missingAccountId.save',
				defaultMessage: 'Salvar'
			},
			placeHolder: {
				id: 'missingAccountId.placeHolder',
				defaultMessage: 'Informar o id da conta'
			}
		});

		return (
			<form onSubmit={this.onAddAccountId} data-testid="component-missing-account-id-form">
				<h1>{formatMessage(messages.error)}</h1>
				<p>{formatMessage(messages.message)}</p>
				<InputText
					type="number"
					value={value}
					onChange={(v) => this.setState({ value: v })}
					placeholder={formatMessage(messages.placeHolder)}
				/>
				<Button type="submit">{formatMessage(messages.save)}</Button>
			</form>
		);
	}
}

MissingAcocuntId.propTypes = {
	history: PropTypes.shape(),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

MissingAcocuntId.defaultProps = {
	history: {}
};

export default withRouter(injectIntl(MissingAcocuntId));
