import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

import { getHoursAndMinutes } from '../../../shared/utility';

const Bubble = ({
	isFromAgent,
	time,
	video,
	children,
	media
}) => {
	const classes = ['Bubble'];
	if (!isFromAgent) classes.push('Bubble--client');
	if (video) classes.push('Bubble--video');
	if (media) classes.push('Bubble--media');
	const parsedTime = getHoursAndMinutes(time || new Date());

	return (
		<div className={classes.join(' ')}>
			<div>{children}</div>
			<span className="Bubble__time">{parsedTime}</span>
		</div>
	);
};

Bubble.propTypes = {
	isFromAgent: PropTypes.bool.isRequired,
	time: PropTypes.string,
	video: PropTypes.bool,
	media: PropTypes.bool
};

Bubble.defaultProps = {
	video: false,
	media: false,
	time: ''
};

export default Bubble;
