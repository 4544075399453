import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class InfiniteList extends React.Component {
	constructor(props) {
		super(props);
		this.isOnEnd = true;
	}

	componentDidMount() {
		this.list.addEventListener('scroll', this._handleScroll.bind(this));
		this.firstSize = this.list.scrollHeight;
		this.firstScroll = false;
	}

	componentDidUpdate() {
		const { autoScrollDown, startOnBottom } = this.props;

		if (autoScrollDown) {
			if (this.isOnEnd) {
				this._scrollDown();
			}
		}

		if (startOnBottom && !this.firstSroll) {
			if (this.firstSize !== this.list.scrollHeight) {
				this._scrollDown();
				this.firstSize = this.list.scrollHeight;
				this.firstSroll = true;
			}
		}
	}

	componentWillUnmount() {
		this.list.removeEventListener('scroll', this._handleScroll.bind(this));
	}

	_scrollDown() {
		if (!this.list) {
			return;
		}

		this.list.scrollTop = this.list.scrollHeight;
	}

	_handleScroll() {
		const { onTop, onBottom } = this.props;

		if (!this.list) {
			return;
		}

		this.isOnEnd = (this.list.scrollTop + this.list.offsetHeight) >= this.list.scrollHeight;

		if (!this.list.scrollTop) {
			onTop();
		}

		if (this.isOnEnd) {
			onBottom();
		}
	}

	render() {
		const {
			type,
			customClass,
			customHeight,
			scrollDirection,
			children
		} = this.props;
		const className = ['InfiniteList', `InfiniteList--${type}`, `InfiniteList--scroll-${scrollDirection}`, customClass];
		const getMainWraper = () => {
			switch (type) {
			case 'table':
				return (
					<div
						className={className.join(' ')}
						ref={(c) => { this.list = c; }}
						onDrop={(e) => e.preventDefault()}
						style={customHeight ? { height: customHeight } : {}}
					>
						{children}
					</div>
				);
			default:
				return (
					<ul
						className={className.join(' ')}
						ref={(c) => { this.list = c; }}
						onDrop={(e) => e.preventDefault()}
						style={customHeight ? { height: customHeight } : {}}
					>
						{children}
					</ul>
				);
			}
		};

		return (getMainWraper());
	}
}

InfiniteList.propTypes = {
	type: PropTypes.oneOf(['list', 'table']),
	customClass: PropTypes.string,
	customHeight: PropTypes.string,
	scrollDirection: PropTypes.oneOf(['', 'vertical', 'horizontal', 'both']),
	autoScrollDown: PropTypes.bool,
	startOnBottom: PropTypes.bool,
	onTop: PropTypes.func,
	onBottom: PropTypes.func
};

InfiniteList.defaultProps = {
	type: 'list',
	customClass: '',
	customHeight: '',
	scrollDirection: 'vertical',
	autoScrollDown: false,
	startOnBottom: false,
	onTop: () => {},
	onBottom: () => {}
};

export default InfiniteList;
