import { isValid as validateCpf } from '@fnando/cpf';
import { isValid as validateCnpj } from '@fnando/cnpj';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export const validateCpfOrCnpj = (value) => {
	if (validateCpf(value)) return true;
	return validateCnpj(value);
};

export const validateEmail = (value) => isEmail(value);

export const validatePhone = (value) => isMobilePhone(value);
