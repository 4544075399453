import axios from 'axios';

import * as actionTypes from './actionTypes';
import { getUrlWithProtocol } from '../../shared/utility';

export const newInteractionBegin = (customer, departmentId) => ({
	type: actionTypes.NEW_INTERACTION_BEGIN,
	payload: { customer, departmentId }
});
export const startInteraction = (inittialMessage) => (dispatch) => {
	dispatch(newInteractionBegin(window.omzVish.customer));
	window.omzVish.newInteraction(inittialMessage);
};

export const newInteractionSuccess = (interactionHash) => ({
	type: actionTypes.NEW_INTERACTION_SUCCESS,
	payload: interactionHash
});
export const createdInteraction = (interactionHash) => (dispatch) => {
	dispatch(newInteractionSuccess(interactionHash));
};

export const updateInteractionSuccess = (interaction) => ({
	type: actionTypes.UPDATE_INTERACTION_SUCCESS,
	payload: interaction
});

export const updateInteraction = (interaction) => (dispatch) => {
	dispatch(updateInteractionSuccess(interaction));
};

export const finishInteractionBegin = () => ({ type: actionTypes.FINISH_INTERACTION_BEGIN });
export const finishInteraction = () => (dispatch) => {
	dispatch(finishInteractionBegin());
	window.omzVish.finishInteraction();
};

export const finishInteractionSuccess = () => ({ type: actionTypes.FINISH_INTERACTION_SUCCESS });
export const finishedInteraction = () => (dispatch) => {
	dispatch(finishInteractionSuccess());
};

export const clearInteractionSuccess = () => ({ type: actionTypes.CLEAR_INTERACTION_SUCCESS });
export const clearInteraction = () => (dispatch) => {
	dispatch(clearInteractionSuccess());
};

export const transferringInteraction = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.TRANSFERRING_INTERACTION,
		payload: data
	});
};

export const selectDepartmentBegin = (departmentId) => ({
	type: actionTypes.SELECT_DEPARTMENT_BEGIN,
	payload: departmentId
});
export const selectDepartment = (departmentId, hasCustomer = false) => (dispatch) => {
	window.omzVish.selectDepartment(departmentId);
	if (hasCustomer) window.omzVish.routeInteraction();
	dispatch(selectDepartmentBegin(departmentId));
};

export const customerFeedbackBegin = () => ({
	type: actionTypes.CUSTOMER_FEEDBACK_BEGIN
});
export const customerFeedbackSuccess = () => ({
	type: actionTypes.CUSTOMER_FEEDBACK_SUCCESS
});
export const customerFeedbackFail = () => ({
	type: actionTypes.CUSTOMER_FEEDBACK_FAIL
});
export const customerFeedbackSkip = () => ({
	type: actionTypes.CUSTOMER_FEEDBACK_SKIP
});
export const customerFeedback = (interactionHash, mood) => (dispatch) => {
	dispatch(customerFeedbackBegin());
	axios.put(`${getUrlWithProtocol('API')}/external/interactions/${interactionHash}`, {
		hash: interactionHash,
		mood
	})
		.then(() => dispatch(customerFeedbackSuccess()))
		.catch(() => dispatch(customerFeedbackSuccess()));
};

export const sendInteractionCopyBegin = () => ({
	type: actionTypes.SEND_INTERACTION_COPY_BEGIN
});
export const sendInteractionCopySuccess = () => ({
	type: actionTypes.SEND_INTERACTION_COPY_SUCCESS
});
export const sendInteractionCopyFail = (error) => ({
	type: actionTypes.SEND_INTERACTION_COPY_FAIL,
	payload: error
});
export const sendInteractionCopy = (interactionHash, email) => (dispatch) => {
	dispatch(sendInteractionCopyBegin());
	axios.post(`${getUrlWithProtocol('API')}/external/interactions/${interactionHash}/send_chat_email`, {
		to_email: email
	})
		.then(() => dispatch(sendInteractionCopySuccess()))
		.catch((error) => dispatch(sendInteractionCopyFail(error)));
};
