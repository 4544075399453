import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const MessageText = ({ content }) => (
	<div className="MessageHeader">
		{content}
	</div>
);

MessageText.propTypes = {
	content: PropTypes.string
};

MessageText.defaultProps = {
	content: ''
};

export default MessageText;
