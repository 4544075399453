import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';
import InputText from '../../Atoms/Inputs/InputText';
import Button from '../../Atoms/Buttons/Button';

import { validateCpfOrCnpj, validateEmail, validatePhone } from '../../../shared/validations';

class CustomerForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: []
		};
	}

	componentDidMount() {
		const { fields } = this.props;
		const parsedFields = fields.map((field) => ({
			...field,
			valid: this.isValid(field)
		}));
		this.setState({ form: parsedFields });
	}

	onChange = (value, fieldName) => {
		const { form } = this.state;
		const fieldIndex = form.findIndex((f) => f.name === fieldName);

		if (fieldIndex > -1) {
			const { isRequired } = form[fieldIndex];
			const newForm = [...form];
			newForm[fieldIndex].value = value;
			newForm[fieldIndex].valid = value ? this.validateField(fieldName, value) : !isRequired;
			this.setState({ form: newForm });
		}
	}

	onBlur = (fieldName) => {
		const { form = [] } = this.state;
		const fieldIndex = form.findIndex((f) => f.name === fieldName);

		if (fieldIndex > -1) {
			const { value, isRequired } = form[fieldIndex];
			const newForm = [...form];
			newForm[fieldIndex].valid = value ? this.validateField(fieldName, value) : !isRequired;
			this.setState({ form: newForm });
		}
	}

	isValid = (field) => (!field.isRequired || field.value.length > 0);

	validateField = (fieldName, value) => {
		if (fieldName === 'document') {
			return validateCpfOrCnpj(value);
		}

		if (fieldName === 'channel_email') {
			return validateEmail(value);
		}

		if (fieldName === 'channel_phone') {
			return validatePhone(value);
		}

		return true;
	}

	onSave = () => {
		const { form } = this.state;
		const { onSave } = this.props;

		onSave(form);
	}

	render() {
		const { form } = this.state;
		const { intl } = this.props;
		const { formatMessage } = intl;

		const messages = defineMessages({
			save: {
				id: 'customerForm.save',
				defaultMessage: 'Salvar'
			}
		});

		const hasRequired = () => form.some((field) => field.isRequired && !field.value);
		const hasInvalid = () => form.some((field) => typeof (field.valid) !== 'undefined' && !field.valid);

		return (
			<div className="CustomerForm">
				<ul className="CustomerForm__fields">
					{form.map((item) => (
						<li key={`CustomerFormField-${item.name}-${item.label}`} className="CustomerForm__fields__field">
							<InputText
								key={`${item.name}-${item.label}`}
								placeholder={item.label}
								value={typeof item.value === 'string' ? item.value : item.value.join[0]}
								isRequired={item.isRequired}
								valid={item.valid}
								onChange={(v) => this.onChange(v, item.name)}
							/>
						</li>
					))}
					<Button
						disabled={hasRequired() || hasInvalid()}
						onClick={this.onSave}
					>
						{formatMessage(messages.save)}
					</Button>
				</ul>
			</div>
		);
	}
}

CustomerForm.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired
	})),
	onSave: PropTypes.func,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

CustomerForm.defaultProps = {
	fields: [],
	onSave: () => {}
};

export default injectIntl(CustomerForm);
