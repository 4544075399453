import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';

const OutOfServicePeriod = ({ className, officeHours, intl }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		title: {
			id: 'outOfServicePeriod.title',
			defaultMessage: 'Horário de atendimento'
		},
		sunday: {
			id: 'outOfServicePeriod.sunday',
			defaultMessage: 'Domingo'
		},
		monday: {
			id: 'outOfServicePeriod.monday',
			defaultMessage: 'Segunda'
		},
		tuesday: {
			id: 'outOfServicePeriod.tuesday',
			defaultMessage: 'Terça'
		},
		wednesday: {
			id: 'outOfServicePeriod.wednesday',
			defaultMessage: 'Quarta'
		},
		thursday: {
			id: 'outOfServicePeriod.thursday',
			defaultMessage: 'Quinta'
		},
		friday: {
			id: 'outOfServicePeriod.friday',
			defaultMessage: 'Sexta'
		},
		saturday: {
			id: 'outOfServicePeriod.saturday',
			defaultMessage: 'Sabado'
		},
		startTime: {
			id: 'outOfServicePeriod.startTime',
			defaultMessage: 'das'
		},
		intervalStartTime: {
			id: 'outOfServicePeriod.intervalStartTime',
			defaultMessage: 'e das'
		},
		from: {
			id: 'outOfServicePeriod.from',
			defaultMessage: 'às'
		}
	});

	const getWeekPeriod = (officeHour) => {
		const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		const activeWeekDays = new Set();

		weekDays.forEach((weekDay) => {
			Object.keys(officeHour).forEach((key) => {
				if (weekDay === key && weekDays.includes(key) && officeHour[key]) {
					activeWeekDays.add(formatMessage(messages[weekDay]));
				}
			});
		});

		return Array.from(activeWeekDays);
	};

	return (
		<div className={`OutOfServicePeriod ${className}`}>
			<h2 className="OutOfServicePeriod__title">
				{formatMessage(messages.title)}
			</h2>
			{officeHours.map((officeHour) => (
				<p className="OutOfServicePeriod__period" key={officeHour.id}>
					<span className="OutOfServicePeriod__period__weekdays">
						{`${getWeekPeriod(officeHour).join(', ')} `}
					</span>
					<span>
						{`${formatMessage(messages.startTime)} `}
					</span>
					{officeHour.intervalStartTime && officeHour.intervalEndTime ? (
						<>
							<span>
								{`${officeHour.startTime} ${formatMessage(messages.from)} ${officeHour.intervalStartTime}, `}
							</span>
							<span>
								{`${formatMessage(messages.intervalStartTime)} `}
							</span>
							<span>
								{`${officeHour.intervalEndTime} ${formatMessage(messages.from)} ${officeHour.endTime}`}
							</span>
						</>
					) : (
						<>
							<span>
								{`${officeHour.startTime} ${formatMessage(messages.from)} ${officeHour.endTime} `}
							</span>
						</>
					)}
				</p>
			))}
		</div>
	);
};

OutOfServicePeriod.propTypes = {
	className: PropTypes.string,
	officeHours: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		intervalStartTime: PropTypes.string,
		intervalEndTime: PropTypes.string,
		sunday: PropTypes.bool,
		monday: PropTypes.bool,
		thirday: PropTypes.bool,
		wednesday: PropTypes.bool,
		thursday: PropTypes.bool,
		friday: PropTypes.bool,
		saturday: PropTypes.bool,
		timezone: PropTypes.shape({
			name: PropTypes.string
		})
	})),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

OutOfServicePeriod.defaultProps = {
	className: '',
	officeHours: []
};

export default injectIntl(OutOfServicePeriod);
