import React from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const Emoji = ({ onSelectEmoji }) => (
	<Picker
		onSelect={onSelectEmoji}
		showPreview={false}
		showSkinTones={false}
	/>
);

Emoji.propTypes = {
	onSelectEmoji: PropTypes.func.isRequired
};

export default Emoji;
