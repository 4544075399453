import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import './index.css';
import Backdrop from '../../Atoms/Backdrop';
import Card from '../../Atoms/Card';
import Button from '../../Atoms/Buttons/Button';

const FinishModal = ({ onFinish, onCancel, intl }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		message: {
			id: 'finishModal.message',
			defaultMessage: 'Você tem certeza que deseja encerrar esse atendimento?'
		},
		confirm: {
			id: 'finishModal.confirm',
			defaultMessage: 'Sim'
		},
		cancel: {
			id: 'finishModal.cancel',
			defaultMessage: 'Não'
		},
		title: {
			id: 'finishModal.title',
			defaultMessage: 'Encerrar atendimento'
		}
	});

	return (
		<Backdrop>
			<Card title={formatMessage(messages.title)}>
				<div className="FinishModal">
					<p>{formatMessage(messages.message)}</p>
					<div className="FinishModal__actions">
						<Button onClick={onFinish}>{formatMessage(messages.confirm)}</Button>
						<Button onClick={onCancel} fill="transparent">{formatMessage(messages.cancel)}</Button>
					</div>
				</div>
			</Card>
		</Backdrop>
	);
};

FinishModal.propTypes = {
	onFinish: PropTypes.func,
	onCancel: PropTypes.func,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

FinishModal.defaultProps = {
	onFinish: () => {},
	onCancel: () => {}
};

export default injectIntl(FinishModal);
