import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import Button from '../../Atoms/Buttons/Button';
import InputText from '../../Atoms/Inputs/InputText';
import Backdrop from '../../Atoms/Backdrop';
import { validateEmail } from '../../../shared/validations';
import './index.css';


const SendChatCopyModal = ({
	description,
	customerEmail,
	loading,
	success,
	error,
	onClose,
	onSend,
	intl
}) => {
	const [email, setEmail] = useState(customerEmail);
	const [valid, setValid] = useState(true);
	const messages = defineMessages({
		title: {
			id: 'sendChatCopyModal.title',
			defaultMessage: 'Cópia da conversa'
		},
		description: {
			id: 'sendChatCopyModal.description',
			defaultMessage: 'Para receber uma cópia do atendimento, digite seu email abaixo:'
		},
		placeholder: {
			id: 'sendChatCopyModal.placeholder',
			defaultMessage: 'Email'
		},
		send: {
			id: 'sendChatCopyModal.send',
			defaultMessage: 'Enviar'
		},
		close: {
			id: 'sendChatCopyModal.close',
			defaultMessage: 'Fechar'
		},
		tryAgain: {
			id: 'sendChatCopyModal.tryAgain',
			defaultMessage: 'Tentar novamente'
		},
		success: {
			id: 'sendChatCopyModal.success',
			defaultMessage: 'Email enviado com sucesso'
		},
		invalid: {
			id: 'sendChatCopyModal.invalid',
			defaultMessage: 'Email inválido'
		},
		error: {
			id: 'sendChatCopyModal.error',
			defaultMessage: 'Falha no envio do email'
		}
	});
	const { formatMessage } = intl;
	const getDescription = () => {
		if (success) return formatMessage(messages.success);
		if (error) return formatMessage(messages.error);
		return description || formatMessage(messages.description);
	};
	const getButtonText = () => {
		if (!valid) return formatMessage(messages.invalid);
		if (error) return formatMessage(messages.error);
		return formatMessage(messages.send);
	};
	const getButtonColor = () => {
		if (success) return 'transparent';
		if (error || !valid) return 'red';
		return 'primary';
	};
	const validate = () => {
		if (!validateEmail(email)) {
			setValid(false);
		} else {
			onSend(email);
		}
	};

	return (
		<Backdrop>
			<div className="SendChatCopyModal">
				<h1 className="SendChatCopyModal__title">
					{formatMessage(messages.title)}
				</h1>
				<p className="SendChatCopyModal__description">
					{getDescription()}
				</p>
				<div className="SendChatCopyModal__input">
					<InputText
						type="email"
						disabled={loading || success}
						value={email}
						placeholder={formatMessage(messages.placeholder)}
						onChange={(v) => {
							setEmail(v);
							setValid(true);
						}}
					/>
				</div>
				{!success && (
					<Button
						className="SendChatCopyModal__save"
						loading={loading}
						fill={getButtonColor()}
						onClick={() => {
							if (success) {
								onClose();
							} else {
								validate(email);
							}
						}}
					>
						{getButtonText()}
					</Button>
				)}
				<Button
					className="SendChatCopyModal__close"
					fill="transparent"
					onClick={onClose}
				>
					{formatMessage(messages.close)}
				</Button>
			</div>
		</Backdrop>
	);
};

SendChatCopyModal.propTypes = {
	description: PropTypes.string,
	customerEmail: PropTypes.string,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired,
	onSend: PropTypes.func,
	onClose: PropTypes.func
};

SendChatCopyModal.defaultProps = {
	description: '',
	customerEmail: '',
	loading: false,
	success: false,
	error: '',
	onSend: () => {},
	onClose: () => {}
};

export default injectIntl(SendChatCopyModal);
