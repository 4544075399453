import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ImgSvg from '../../../Atoms/ImgSvg';
import ButtonIcon from '../../../Atoms/Buttons/ButtonIcon';
import './index.css';

const CallControls = ({
	time,
	enabledVideo,
	enabledMic,
	onExpand,
	onToggleVideo,
	onToggleMic,
	onEnd,
	autostartWebcam
}) => (
	<div className="CallControls">
		{ time ? <span className="CallControls__time">{time}</span> : null}
		<div className="CallControls__buttons">
			<span className="CallControls__button">
				<ButtonIcon circle fill="grey" svg={<ImgSvg name="expand" />} click={onExpand} />
			</span>
			<span className={`CallControls__button ${!enabledVideo ? 'CallControls__button--disabled' : ''}`}>
				<ButtonIcon
					circle
					fill={`${enabledVideo ? 'grey' : 'white'}`}
					svg={<ImgSvg name={enabledVideo ? 'video' : 'video-off'} />}
					click={onToggleVideo}
				/>
			</span>
			<span className={`CallControls__button ${!enabledMic ? 'CallControls__button--disabled' : ''}`}>
				<ButtonIcon
					circle
					fill={`${enabledMic ? 'grey' : 'white'}`}
					svg={<ImgSvg name={enabledMic ? 'microphone' : 'microphone-off'} />}
					click={onToggleMic}
				/>
			</span>
			{!autostartWebcam && (
				<span className="CallControls__button">
					<ButtonIcon circle fill="red" svg={<ImgSvg name="end-call" />} click={onEnd} />
				</span>
			)}
		</div>
	</div>
);

CallControls.propTypes = {
	time: PropTypes.string,
	enabledVideo: PropTypes.bool,
	enabledMic: PropTypes.bool,
	onExpand: PropTypes.func,
	onToggleVideo: PropTypes.func,
	onToggleMic: PropTypes.func,
	onEnd: PropTypes.func,
	autostartWebcam: PropTypes.bool.isRequired
};

CallControls.defaultProps = {
	time: '',
	enabledVideo: true,
	enabledMic: true,
	onExpand: () => {},
	onToggleVideo: () => {},
	onToggleMic: () => {},
	onEnd: () => {}
};

const mapStateToProps = (state) => ({
	autostartWebcam: state.appearance.data.jsonAppearance.preferences.autostartWebcam
});

export default connect(mapStateToProps)(CallControls);
