import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './index.css';
import * as actions from '../../../store/actions';
import MessageAudio from '../../Molecules/Messages/MessageAudio';
import MessageVideo from '../../Molecules/Messages/MessageVideo';
import MessageFile from '../../Molecules/Messages/MessageFile';
import MessageText from '../../Molecules/Messages/MessageText';
import MessageImage from '../../Molecules/Messages/MessageImage';
import MessageTyping from '../../Molecules/Messages/MessageTyping';
import Call from '../Call';
import ZenviaInfo from '../../Atoms/ZenviaInfo';
import AvatarName from '../../Atoms/AvatarName';
import WaitMedia from '../../Atoms/WaitMedia';
import InfiniteList from '../../Molecules/InfiniteList';

class MessageList extends Component {
	hasMissingCustomerRequiredFields = (interactionCustomerFields) => {
		const { appearance = {} } = this.props;
		const { customerFields = [] } = appearance;

		customerFields.some((field) => {
			if (field.isRequired && !interactionCustomerFields[field.name]) {
				return true;
			}

			return false;
		});

		return false;
	}

	waitingToBeAnswered = (currentState) => (
		currentState.toUpperCase() === 'QUEUED' || currentState.toUpperCase() === 'RINGING'
	)

	render() {
		const {
			showVideo,
			interaction,
			messages,
			appearance = {}
		} = this.props;
		const {
			currentState = '',
			typing,
			agent = {}
		} = interaction;
		const { name, photo } = agent;
		const { partnerId } = appearance;

		return (
			<>
				<div className="MessageList">
					{
						this.waitingToBeAnswered(currentState)
						&& <WaitMedia waitMediaUrl={appearance.jsonAppearance.preferences.waitMediaUrl} />
					}
					{showVideo && currentState.toUpperCase() === 'TALKING' && (<Call />)}
					<InfiniteList autoScrollDown customHeight={showVideo ? 'calc(100% - 27.5rem)' : ''}>
						{messages.map((message) => {
							const {
								/* eslint-disable-next-line camelcase */
								user_id,
								source,
								id,
								tempId,
								contentType,
								agentPhoto,
								agentName
							} = message;
							/* eslint-disable-next-line camelcase */
							const isFromAgent = typeof (user_id) !== 'undefined' || (source && source.toLowerCase() === 'agent') || false;
							const messageId = id || tempId;
							let messageComponent = null;

							const messageClasses = ['MessageList__item'];
							if (!isFromAgent) messageClasses.push('MessageList__item--client');
							let verifiedContentType = contentType;
							if (verifiedContentType.match('image')) verifiedContentType = 'image';
							if (verifiedContentType.match('audio')) verifiedContentType = 'audio';
							if (verifiedContentType.match('video')) verifiedContentType = 'video';


							switch (verifiedContentType.toLowerCase()) {
							case 'audio':
								messageComponent = <MessageAudio content={message} isFromAgent={isFromAgent} />;
								break;
							case 'image':
								messageComponent = <MessageImage content={message} isFromAgent={isFromAgent} />;
								break;
							case 'video':
								messageComponent = <MessageVideo content={message} isFromAgent={isFromAgent} />;
								break;
							case 'single_choice': {
								const { createdAt, content } = message;
								const parsedMessage = { createdAt };

								try {
									parsedMessage.content = JSON.parse(content).message;
								} catch (error) {
									parsedMessage.content = content;
								}

								messageComponent = (
									<MessageText
										content={parsedMessage}
										isFromAgent={isFromAgent}
									/>
								);
								break;
							}
							case 'text':
							case 'welcome':
							case 'wait':
								messageComponent = <MessageText content={message} isFromAgent={isFromAgent} />;
								break;
							default:
								messageComponent = <MessageFile content={message} isFromAgent={isFromAgent} />;
							}

							return (
								<div className={messageClasses.join(' ')} key={`Message-${messageId}`}>
									{ isFromAgent && messageComponent && (
										<AvatarName url={agentPhoto} name={agentName} />
									)}
									{ messageComponent }
								</div>
							);
						})}
						{typing && (currentState !== 'ENDED' || currentState !== 'UNAVAILABLE') && (
							<div className="MessageList__item" key="message-agent-typing">
								<AvatarName url={photo} name={name} />
								<MessageTyping />
							</div>
						)}
					</InfiniteList>
				</div>
				<div className="MessageList__logo">
					{!partnerId && <ZenviaInfo />}
				</div>
			</>
		);
	}
}

MessageList.propTypes = {
	appearance: PropTypes.shape(),
	interaction: PropTypes.shape(),
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			user_id: PropTypes.string,
			source: PropTypes.string,
			id: PropTypes.number,
			tempId: PropTypes.number,
			contentType: PropTypes.string
		})
	),
	showVideo: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

MessageList.defaultProps = {
	appearance: {},
	interaction: {},
	messages: [],
	showVideo: false
};

const mapStateToProps = (state) => ({
	interaction: state.interaction.data,
	appearance: state.appearance.data,
	messages: state.message.messages,
	showVideo: state.interface.showVideo,
	state
});

const mapActionsToProps = (dispatch) => ({
	selectDepartment: (departmentId) => dispatch(actions.selectDepartment(departmentId)),
	sendMessage: (message) => dispatch(actions.sendMessage(message)),
	fakeAgentMessage: (message) => dispatch(actions.fakeAgentMessage(message)),
	attendanceMessages: (appearance, addWaitMessage) => {
		dispatch(actions.attendanceMessages(appearance, addWaitMessage));
	},
	addCustomerForm: (title, fields) => dispatch(actions.addCustomerForm(title, fields)),
	updateInteraction: (interaction) => dispatch(actions.updateInteraction(interaction)),
	addDepartmentSelect: (appearance) => dispatch(actions.addDepartmentSelect(appearance))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(MessageList));
