import React from 'react';

import './index.css';

import MissingAccountId from '../../Organisms/MissingAccountId';

const ErrorTemplate = () => (
	<div className="ErrorTemplate">
		<MissingAccountId />
	</div>
);

export default ErrorTemplate;
