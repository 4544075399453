import React from 'react';
import PropTypes from 'prop-types';
import { SvgLoader, SvgProxy } from 'react-svgmt';

const ImgSvg = ({ className, name }) => {
	const svgFile = require(`../../../assets/images/svg/${name}.svg`);

	const svg = (
		<SvgLoader className={className} path={svgFile}>
			<SvgProxy selector="path" />
		</SvgLoader>
	);

	return svg;
};

ImgSvg.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired
};

ImgSvg.defaultProps = {
	className: ''
};

export default ImgSvg;
