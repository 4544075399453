import React from 'react';
import PropTypes from 'prop-types';

import Bubble from '../../../Atoms/Bubble';
import './index.css';

const MessageVideo = ({ content, isFromAgent }) => {
	const { url, createdAt } = content;
	const classes = ['MessageImage'];

	if (!isFromAgent) classes.push('MessageImage--client');

	return (
		<div className={classes.join(' ')}>
			<Bubble isFromAgent={isFromAgent} time={createdAt} media>
				<img src={url} alt="" />
			</Bubble>
		</div>
	);
};

MessageVideo.propTypes = {
	content: PropTypes.shape({
		createdAt: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	}).isRequired,
	isFromAgent: PropTypes.bool.isRequired
};

export default MessageVideo;
