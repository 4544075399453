import React from 'react';

import './index.css';
import ZENVIA_LOGO from '../../../shared/consts';

const ZenviaInfo = () => (
	<div className="ZenviaInfo">
		<img src={ZENVIA_LOGO} alt="logo" />
	</div>
);

export default ZenviaInfo;
