import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import { getAverageMinutesFromSeconds, getAverageHoursFromMinutes } from '../../../shared/utility';

const ResponseTime = ({
	className,
	seconds,
	smallText,
	intl
}) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		lessThanOne: {
			id: 'responseTime.lessThanOne',
			defaultMessage: 'menos de um minuto'
		},
		hours: {
			id: 'responseTime.hours',
			defaultMessage: 'horas'
		},
		minutes: {
			id: 'responseTime.minutes',
			defaultMessage: 'minutos'
		},
		responseTime: {
			id: 'responseTime.responseTime',
			defaultMessage: 'Tempo de resposta'
		},
		averageResponse: {
			id: 'responseTime.averageResponse',
			defaultMessage: 'Tempo médio de resposta'
		}
	});

	const averageMinutesText = () => {
		const minutes = getAverageMinutesFromSeconds(seconds);

		if (minutes < 1) {
			return formatMessage(messages.lessThanOne);
		}

		if (minutes > 60) {
			return `${getAverageHoursFromMinutes(minutes)} ${formatMessage(messages.hours)}`;
		}

		return `${minutes} ${formatMessage(messages.minutes)}`;
	};

	const mainText = smallText ? (
		formatMessage(messages.responseTime)
	) : formatMessage(messages.averageResponse);

	return (
		<p className={`ResponseTime ${className}`}>
			{`${mainText} ${averageMinutesText()}`}
		</p>
	);
};

ResponseTime.propTypes = {
	className: PropTypes.string,
	seconds: PropTypes.number,
	smallText: PropTypes.bool,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

ResponseTime.defaultProps = {
	className: '',
	seconds: 0,
	smallText: false
};

export default injectIntl(ResponseTime);
