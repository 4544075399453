import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import * as actions from '../../../store/actions';
import { fillCustomerValues, findGetParameter } from '../../../shared/utility';
import Loader from '../../Atoms/Loader';
import Button from '../../Atoms/Buttons/Button';
import ChatInputText from '../../Atoms/Inputs/ChatInputText';
import SingleChoice from '../../Molecules/SingleChoice';
import SelectDepartment from '../../Molecules/SelectDepartment';
import CustomerForm from '../../Molecules/CustomerForm';
import Send from '../../Molecules/Send';
import Emoji from '../../Molecules/Emoji';
import Attachment from '../../Molecules/Attachment';
import ImgSvg from '../../Atoms/ImgSvg';
import MessageHeader from '../../Molecules/Messages/MessageHeader';
import './index.css';

class ChatInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			typing: false,
			emojiShown: false,
			blockedSize: true,
			customerUpdated: false
		};
	}

	componentDidMount() {
		this.setState({ blockedSize: false });
	}

	componentDidUpdate() {
		const { customerUpdated } = this.state;
		const { interaction, appearance } = this.props;
		const { interactionHash, customer } = interaction;
		const {
			useUrlCustomer,
			urlCustomer
		} = appearance;

		if (interactionHash && customer && !customerUpdated && useUrlCustomer) {
			this.saveCustomerForm(urlCustomer);
		}
	}

	onSelectDepartmentId = (departmentId) => {
		const { selectDepartment } = this.props;
		const hasCustomer = findGetParameter(window.omzVish.url.search, 'has_customer') === 'true';

		selectDepartment(departmentId, hasCustomer);
	}

	parseCustomerBeforeSend = (fields) => {
		const parsedFields = {};

		fields.forEach((field) => {
			parsedFields[field.name] = field.value;
		});

		return parsedFields;
	}

	saveCustomerForm = (fields) => {
		const { interaction, updateInteraction } = this.props;
		const { customer = {}, interactionHash } = interaction;
		const newFields = this.parseCustomerBeforeSend(fields);
		const newCustomer = { interactionHash, ...customer, fields: newFields };
		const hasCustomer = findGetParameter(window.omzVish.url.search, 'has_customer') === 'true';

		window.omzVish.updateCustomer(newCustomer);

		if (!hasCustomer) window.omzVish.routeInteraction();

		this.setState({ customerUpdated: true });

		updateInteraction({ ...interaction, customer: newCustomer });
	}

	onChange = (inputValue) => {
		this.setState({ value: inputValue });

		const { typing } = this.state;

		if (inputValue && !typing) {
			this.setState({ typing: true });
			window.omzVish.interactionInfo('typing');
		} else if (!inputValue && typing) {
			this.setState({ typing: false });
			window.omzVish.interactionInfo('cleared');
		}
	};

	onSelectEmoji = (e) => {
		const { value } = this.state;
		const emojiPic = e.native;
		this.setState({ value: value + emojiPic });
	}

	toggleEmoji = () => {
		const { emojiShown } = this.state;
		this.setState({ emojiShown: !emojiShown });
	}

	onSend = () => {
		const { sendMessage } = this.props;
		const { value } = this.state;
		if (value.length > 0) {
			const message = value.trim();
			sendMessage(message, true);
			this.setState({ value: '', emojiShown: false });
		}
	}

	onSelectChoice = (messageId, messageType, options, value) => {
		const { sendMessage } = this.props;
		const selectedOption = options.find((option) => option.value === value);

		if (selectedOption) {
			sendMessage(selectedOption.label, true, messageId, value, messageType);
		}
	}

	hasRequiredEmptyFields = (fields, customer) => {
		const fillFields = customer ? fields.find((field) => field.isRequired && customer[field.name] === '') : false;

		return Boolean(fillFields);
	}

	getMessageHeaderContent = () => {
		const { appearance = {}, interaction, intl } = this.props;
		const { departmentId, currentState, customer = {} } = interaction;
		const { customerFields, jsonAppearance = {} } = appearance;
		const { messages = {} } = jsonAppearance;
		const { formatMessage } = intl;
		const { customerUpdated } = this.state;
		const hasCustomer = findGetParameter(window.omzVish.url.search, 'has_customer');
		const customerOnUrl = hasCustomer !== null && hasCustomer !== 'false';
		const hasRequiredEmptyFields = this.hasRequiredEmptyFields(customerFields, customer.fields);
		const hasToFillFields = hasRequiredEmptyFields || !interaction.reconnected;

		const intlObjectMessages = defineMessages({
			fillFields: {
				id: 'messageList.fillFields',
				defaultMessage: 'Por favor, preencha os dados abaixo'
			},
			waitAgent: {
				id: 'messageList.waitAgent',
				defaultMessage: 'Aguarde que já iremos atender'
			},
			unavailable: {
				id: 'messageList.unavailable',
				defaultMessage: 'Nenhum atendente disponível no momento'
			},
			finished: {
				id: 'messageList.finished',
				defaultMessage: 'Atendimento finalizado'
			},
			onHoldMessage: {
				id: 'messageList.onHoldMessage',
				defaultMessage: 'Deixe seu recado, que retornaremos assim que possível'
			}
		});

		if (!departmentId) {
			return typeof (messages['department-title']) !== 'undefined'
				? messages['department-title']
				: formatMessage(intlObjectMessages.fillFields);
		}

		if (hasToFillFields && !customerUpdated && !customerOnUrl) {
			return formatMessage(intlObjectMessages.fillFields);
		}

		if (!currentState) return '';

		switch (currentState.toUpperCase()) {
		case 'QUEUED':
		case 'RINGING':
			return typeof (messages['message-inv-wait']) !== 'undefined' && messages['message-inv-wait']
				? messages['message-inv-wait']
				: formatMessage(intlObjectMessages.waitAgent);
		case 'ON_HOLD':
			return typeof (messages['on-hold']) !== 'undefined' && messages['on-hold']
				? messages['on-hold']
				: formatMessage(intlObjectMessages.onHoldMessage);
		case 'UNAVAILABLE':
			return formatMessage(intlObjectMessages.unavailable);
		case 'ENDED':
			return formatMessage(intlObjectMessages.finished);
		default:
			return '';
		}
	};

	render() {
		const {
			interaction = {},
			appearance = {},
			messages = [],
			onFailedToParseSingleChoice,
			onSendAttachment,
			intl
		} = this.props;
		const {
			interactionHash,
			currentState,
			departmentId,
			customer = {}
		} = interaction;
		const {
			customerFields,
			useUrlDepartment,
			urlDepartment
		} = appearance;
		const {
			value,
			emojiShown,
			blockedSize,
			customerUpdated
		} = this.state;
		const hasCustomer = findGetParameter(window.omzVish.url.search, 'has_customer');
		const customerOnUrl = hasCustomer !== null && hasCustomer !== 'false';
		const hasRequiredEmptyFields = this.hasRequiredEmptyFields(customerFields, customer.fields);
		const hasToFillFields = hasRequiredEmptyFields || !interaction.reconnected;
		const canSendAttachment = appearance && appearance.licenseType ? appearance.licenseType !== 'INITIAL' : false;
		const dynamicIcons = !value ? (
			<>
				{canSendAttachment && <Attachment onSendAttachment={onSendAttachment} />}
			</>
		) : <Send onSend={this.onSend} />;
		const showMessageHeader = (
			interactionHash && (['QUEUED', 'RINGING', 'ON_HOLD', 'UNAVAILABLE', 'ENDED'].includes(currentState.toUpperCase()) || !departmentId)
		);
		const messageHeader = (
			<>
				{ showMessageHeader && <MessageHeader content={this.getMessageHeaderContent()} /> }
			</>
		);
		const { formatMessage } = intl;
		const intlMessages = defineMessages({
			placeholder: {
				id: 'chatInput',
				defaultMessage: 'Escreva aqui'
			},
			outOfServiceMessage: {
				id: 'messageList.outOfServiceMessage',
				defaultMessage: 'Deixe o seu recado'
			}
		});

		if (!interactionHash) return <Loader />;

		if (!departmentId) {
			if (useUrlDepartment && !departmentId) {
				this.onSelectDepartmentId(urlDepartment);
			} else {
				if (Object.keys(appearance).length === 0) return null;
				const { listDepartmentWrapper = [], jsonAppearance } = appearance;
				const appearanceMessages = jsonAppearance.messages ? jsonAppearance.messages : {};
				const choices = listDepartmentWrapper.map((department) => {
					const {
						name,
						id,
						officeHours,
						holiday
					} = department;
					const messageType = holiday ? 'holiday' : 'out-of-service';
					const outOfServiceMessage = typeof (appearanceMessages[messageType]) !== 'undefined'
						? appearanceMessages[messageType]
						: formatMessage(intlMessages.outOfServiceMessage);

					return {
						label: name,
						value: id,
						officeHours,
						outOfServiceMessage,
						holiday
					};
				});
				return (
					<>
						{messageHeader}
						<SelectDepartment
							choices={choices}
							onSelectChoice={this.onSelectDepartmentId}
						/>
					</>
				);
			}
		}

		if (hasToFillFields && !customerUpdated && !customerOnUrl) {
			const filledCustomer = fillCustomerValues(customerFields, customer.fields);

			return (
				<>
					{messageHeader}
					<CustomerForm
						fields={filledCustomer}
						onSave={this.saveCustomerForm}
					/>
				</>
			);
		}

		if (currentState === 'CREATED') {
			window.omzVish.routeInteraction();
		}

		if (messages.length > 0) {
			const { contentType = '', source = '' } = messages[messages.length - 1];

			if (contentType.toUpperCase() === 'SINGLE_CHOICE' && source.toUpperCase() === 'AGENT') {
				const { content, id } = messages[messages.length - 1];

				try {
					const { options } = JSON.parse(content);
					return (
						<>
							{messageHeader}
							<SingleChoice
								choices={options}
								onSelectChoice={(selectedOption) => (
									this.onSelectChoice(id, contentType, options, selectedOption)
								)}
							/>
						</>
					);
				} catch (error) { onFailedToParseSingleChoice(error); }
			}
		}

		if (currentState && (currentState.toUpperCase() === 'ENDED' || currentState.toUpperCase() === 'UNAVAILABLE')) {
			return (
				<>
					{messageHeader}
				</>
			);
		}

		return (
			<>
				{messageHeader}
				{emojiShown ? (
					<div className="ChatInput__emojiPicker">
						<Emoji
							onSelectEmoji={this.onSelectEmoji}
						/>
					</div>
				) : null}
				<div className={`ChatInput ${blockedSize ? 'ChatInput__blocked-size' : ''}`}>
					<ChatInputText
						inputValue={value}
						onChange={this.onChange}
						onSend={this.onSend}
						placeholder={formatMessage(intlMessages.placeholder)}
					/>
					<div className="ChatInput__actions">
						<Button
							fill="transparent"
							className="ChatInput__emoji"
							onClick={this.toggleEmoji}
						>
							<ImgSvg name="good" />
						</Button>
						{dynamicIcons}
					</div>
				</div>
			</>
		);
	}
}

ChatInput.propTypes = {
	appearance: PropTypes.shape(),
	interaction: PropTypes.shape().isRequired,
	messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	sendMessage: PropTypes.func.isRequired,
	selectDepartment: PropTypes.func.isRequired,
	updateInteraction: PropTypes.func.isRequired,
	onFailedToParseSingleChoice: PropTypes.func,
	onSendAttachment: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

ChatInput.defaultProps = {
	appearance: {},
	onFailedToParseSingleChoice: () => {}
};

const mapStateToProps = (state) => ({
	interaction: state.interaction.data,
	appearance: state.appearance.data,
	messages: state.message.messages
});

const mapActionsToProps = (dispatch) => ({
	selectDepartment: (departmentId, hasCustomer) => {
		dispatch(actions.selectDepartment(departmentId, hasCustomer));
	},
	sendMessage: (message, sendToVish, messageId, choice, contentType) => {
		dispatch(actions.sendMessage(message, sendToVish, messageId, choice, contentType));
	},
	updateInteraction: (interaction) => dispatch(actions.updateInteraction(interaction)),
	onSendAttachment: (info) => dispatch(actions.sendAttachment(info))
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ChatInput));
