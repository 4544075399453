import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import ImgSvg from '../../../Atoms/ImgSvg';
import './index.css';

class RateStar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			hoveredItem: -1
		};
	}

	onCheck = (value) => {
		const { onSelect } = this.props;

		this.setState({ checked: true });
		onSelect(value);
	};

	render() {
		const { options } = this.props;
		const { checked, hoveredItem } = this.state;
		const classes = checked ? ['RateStar', 'RateStar--checked'] : ['RateStar'];

		return (
			<div className={classes.join(' ')}>
				<form className="RateStar__form">
					{options.map((option, i) => (
						<Fragment key={`RateOption-${option.value}`}>
							<label
								onMouseEnter={() => this.setState({ hoveredItem: i })}
								onMouseLeave={() => this.setState({ hoveredItem: -1 })}
								htmlFor={i}
								className={`RateStar__label ${hoveredItem >= i ? 'RateStar__label--hovered' : null}`}
							>
								<input
									onChange={() => this.onCheck(option.value)}
									type="radio"
									name="value"
									id={i}
									className="RateStar__input"
									value={option.value}
								/>
								<ImgSvg name="star-glyph" />
							</label>
						</Fragment>
					))}
				</form>
			</div>
		);
	}
}

RateStar.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired
		})
	),
	onSelect: PropTypes.func
};

RateStar.defaultProps = {
	options: [],
	onSelect: () => {}
};

export default RateStar;
