import React from 'react';

import './index.css';
import ChatHeader from '../../Organisms/ChatHeader';
import ChatInput from '../../Organisms/ChatInput';
import MessageList from '../../Organisms/MessageList';

const ChatTemplate = () => (
	<div className="ChatTemplate">
		<ChatHeader />
		<div className="ChatTemplate__call">
			<MessageList />
		</div>
		<div className="ChatTemplate__chat">
			<ChatInput />
		</div>
	</div>
);

export default ChatTemplate;
