import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import * as actions from '../../../store/actions';
import './index.css';
import AvatarNameList from '../../Molecules/AvatarNameList';
import ChatInputText from '../../Atoms/Inputs/ChatInputText';
import Send from '../../Molecules/Send';
import ResponseTime from '../../Molecules/ResponseTime';
import ImgSvg from '../../Atoms/ImgSvg';
import Box from '../../Atoms/Box';

class StartInteraction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ''
		};
	}

	componentDidMount() {
		const urlParams = (new URL(document.location)).searchParams;
		if (urlParams.get('skip_home') === 'true') {
			window.history.replaceState(null, null, window.location.pathname);
			this.onSend();
		}
	}

	onSend = () => {
		const {
			history,
			startInteraction,
			sendMessage
		} = this.props;
		const { push } = history;
		const { value } = this.state;
		const message = value.trim() || null;

		push('/chat');
		startInteraction(message);
		if (message) sendMessage(message);
	}

	onChange = (inputValue) => {
		this.setState({ value: inputValue });
	};

	render() {
		const { value } = this.state;
		const {
			onlineAgents,
			appearance = {},
			intl,
			delay
		} = this.props;
		const { averageService = 0 } = onlineAgents;
		const { agents = [] } = onlineAgents;
		const { jsonAppearance = {}, listDepartmentWrapper = [] } = appearance;
		const { preferences = {} } = jsonAppearance;
		const { showAverageService = true, showOnlineAgents = true } = preferences;
		const { formatMessage } = intl;

		let facebook = null;
		let telegram = null;
		let whatsapp = null;
		if (listDepartmentWrapper) {
			listDepartmentWrapper.forEach((department) => {
				const { listChannelWrapper } = department;
				listChannelWrapper.forEach((channel) => {
					const { name, action } = channel;
					if (name === 'FACEBOOK') facebook = action;
					if (name === 'TELEGRAM') telegram = action;
					if (name === 'WHATSAPP') whatsapp = action;
				});
			});
		}

		const translateMessages = defineMessages({
			callToAction: {
				id: 'startInteraction.callToAction',
				defaultMessage: 'Inicie uma conversa'
			},
			otherChannels: {
				id: 'startInteraction.otherChannels',
				defaultMessage: 'Outros canais:'
			},
			sendMessage: {
				id: 'startInteraction.sendMessage',
				defaultMessage: 'Enviar mensagem'
			},
			outOfServiceMessage: {
				id: 'startInteraction.outOfServiceMessage',
				defaultMessage: 'Fora do horário de atendimento.'
			},
			outOfServiceLeaveMessage: {
				id: 'startInteraction.outOfServiceLeaveMessage',
				defaultMessage: 'Deixar recado'
			}
		});

		return (
			<Box className="StartInteraction" delay={delay}>
				<div className="StartInteraction__info">
					<>
						<p className="StartInteraction__Header">
							{formatMessage(translateMessages.callToAction)}
						</p>
						{showAverageService && (
							<ResponseTime
								className="StartInteraction__agents__time"
								seconds={averageService}
							/>
						)}
						{agents.length > 0 && (
							<div className="StartInteraction__agents">
								{showOnlineAgents && (
									<AvatarNameList agentList={agents} />
								)}
							</div>
						)}
					</>
				</div>
				<div className="StartInteraction__field">
					<>
						<ChatInputText
							inputValue={value}
							onChange={this.onChange}
							onSend={this.onSend}
							placeholder={formatMessage(translateMessages.sendMessage)}
						/>
						<Send onSend={this.onSend} />
					</>
				</div>
				{(whatsapp || telegram || facebook) && (
					<>
						<div className="StartInteraction__channels">
							<p>{formatMessage(translateMessages.otherChannels)}</p>
							<div className="StartInteraction__icons">
								{whatsapp && (
									<a href={`https://wa.me/${whatsapp}`} target="_blank" rel="noopener noreferrer">
										<div className="StartInteraction__icons__item">
											<ImgSvg name="whatsapp" />
										</div>
									</a>
								)}
								{telegram && (
									<a href={`https://telegram.me/${telegram}`} target="_blank" rel="noopener noreferrer">
										<div className="StartInteraction__icons__item">
											<ImgSvg name="telegram" />
										</div>
									</a>
								)}
								{facebook && (
									<a href={`http://www.facebook.com/messages/t/${facebook}`} target="_blank" rel="noopener noreferrer">
										<div className="StartInteraction__icons__item">
											<ImgSvg name="messenger" />
										</div>
									</a>
								)}
							</div>
						</div>
					</>
				)}
			</Box>
		);
	}
}

StartInteraction.propTypes = {
	delay: PropTypes.number,
	appearance: PropTypes.shape(),
	history: PropTypes.shape().isRequired,
	onlineAgents: PropTypes.shape().isRequired,
	startInteraction: PropTypes.func.isRequired,
	sendMessage: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
};

StartInteraction.defaultProps = {
	delay: 100,
	appearance: {}
};

const mapStateToProps = (state) => ({
	appearance: state.appearance.data,
	onlineAgents: state.appearance.onlineAgents
});

const mapActionsToProps = (dispatch) => ({
	startInteraction: (message) => dispatch(actions.startInteraction(message)),
	sendMessage: (message) => dispatch(actions.sendMessage(message, false))
});

export default connect(mapStateToProps, mapActionsToProps)(
	injectIntl(withRouter(StartInteraction))
);
