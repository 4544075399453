import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	showVideo: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.TOGGLE_VIDEO: return updateObject({
		state,
		showVideo: !state.showVideo
	});
	case actionTypes.CLOSE_VIDEO: return updateObject({
		state,
		showVideo: false
	});
	default:
		return state;
	}
};

export default reducer;
