import React from 'react';

import './index.css';

const Backdrop = ({ children }) => (
	<div className="Backdrop">
		{children}
	</div>
);

export default Backdrop;
