"use strict";
import { Socket } from "./phoenix.js";
import { getUrlWithProtocol, findGetParameter } from '../shared/utility';
import axios from 'axios';

var OmzVish = function () {
  this.getSavedSessionKey = function () {
    return findGetParameter(window.location.href, 'sessionKey');
  };

  this.websocket;
  this.events = {};
  this.channel;
  this.canRoute = true;
  this.accountId;
  this.departmentId;
  this.sessionKey = this.getSavedSessionKey();
  this.currentInteraction = '';
  this.ready = false;
  this.lastInteraction;
  this.departmentName = '';
  this.media = '';
  this.agentName = '';
  this.location;
  this.customer;
  this.metadata = null;
  this.externalHistory = null;
  this.state = 'idle';
  this.history = [];
  this.candidates = [];
  this.position;
  this.queued = false;
  this.vish_url = getUrlWithProtocol('VISH_WS');
  this.api_url = getUrlWithProtocol('API');
  this.stream;
  this.myConnection = null;
  this.localVideo = document.getElementById('localVideo');
  this.remoteVideo = document.getElementById('remoteVideo');
  this.remoteAudio = document.getElementById('remoteAudio');
  this.url = new URL(window.location.href);

  window.addEventListener('beforeunload', function (e) {
    if (this.currentInteraction !== "null" && !!this.currentInteraction) {
      this.interactionInfo('cleared');
    };
  }.bind(this), false);
};

OmzVish.prototype = {
  connect: function (accountId) {
    this.accountId = accountId
    this.websocket = new Socket(this.vish_url, {
      params: { user_id: `vish:${accountId}:${this.sessionKey}` }
    })
    this.websocket.connect()

    this.customer = {};

    this.channel = this.websocket.channel(`chat:${accountId}`, {})

    this.channel.join()
      .receive("ok", resp => {
        if (this.sessionKey) {
          this.channel.push("reconnectInteraction", { sessionKey: this.sessionKey })
        } else {
          this.ready = true;
        }
        if (this.customer && this.customer.customerKey) {
          this.channel.push("getCustomer", { customerKey: this.customer.customerKey })
        }
      })
      .receive("error", resp => { console.log("Unable to join", resp) })

    this.createHandlers();
    this.addHistory();
  },
  createHandlers: function () {
    var self = this;
    this.channel.on('invite', data => {
      self.events['invite'](data);
      self.state = 'invited';
      self.sendCustomer(false);
    });
    this.channel.on('updateCustomer', data => {
      self.setCustomer(data);
    });
    this.channel.on('phx_reply', data => {
      switch (data.status) {
        case 'ok':
          switch (data.response.type) {
            case 'createdInteraction':
              self.setCurrentInteraction(data.response.interaction.interactionHash);
              self.events['createdInteraction'](data.response.interaction);
              if (data.response.interaction.customer) {
                self.updateCustomer(data.response.interaction.customer, false);
              }
              self.updateVisitor(data.response.interaction.id);
              break;
            case 'receivedMessage':
              self.events['receivedMessage'](data.response);
              break;
            case 'reconnectedInteraction':
              self.setCurrentInteraction(data.response.interaction.interactionHash);
              self.setCustomer(data.response.interaction.customer);
              let interaction = data.response.interaction;
              interaction.reconnected = true;
              this.ready = true;
              self.events['reconnectedInteraction'](interaction);
              break;
            case 'updatedCustomer':
              self.updateCustomer(data.response.customer, false);
              self.events['updatedCustomer'](self.customer);
            case 'getCustomer':
              self.setCustomer(data.response.customer);
              break;
          }
          break;
        case 'error':
          switch (data.response.type) {
            case 'newInteraction':
              self.setCurrentInteraction(null);
              break;
            case 'invalidInteraction':
              this.ready = true;
              self.setCurrentInteraction(null);
              self.events['invalidInteraction']();
              break;
            case 'notUpdatedCustomer':
              self.events['notUpdatedCustomer']();
              break;
            default:
              self.events['unrecognizedError']();
              self.setCurrentInteraction(null);
          }
          break;
      }
    });
    this.channel.on('phx_error', () => {
      self.events['unrecognizedError']();
      self.setCurrentInteraction(null);
    });
    this.channel.on('customer_message', data => {
      switch (data.type) {
        case 'invite':
          self.events['invite'](data.payload);
          self.state = 'invited';
          break;
        case 'newMessage':
          self.deliveredMessage(data.messageId);
          self.events['newMessage'](data);
          break;
        case 'waiting':
          self.position = null;
          self.setCurrentInteraction(data.interactionHash);
          self.events['waiting'](data.interactionHash);
          break;
        case 'acceptedInteraction':
          self.position = null;
          self.queued = false;
          self.setCurrentInteraction(data.interactionHash);
          data.media = data.interactionType;
          self.events['acceptedInteraction'](data);
          break;
        case 'finishedInteraction':
          self.position = null;
          this.lastInteraction = this.currentInteraction;
          self.setCurrentInteraction(null);
          self.events['finishedInteraction'](data);
          self.closeAgentVideo();
          break;
        case 'on_hold':
          self.events['on_hold'](data);
          break;
        case 'transferring':
          self.events['transferring'](data);
          break;
        case 'unavailable':
          self.events['unavailable'](data);
          break;
        case 'typing':
          self.events['typing'](data);
          break;
        case 'cleared':
          self.events['cleared'](data);
          break;
        case 'position':
          if (data.position > 0) {
            setTimeout(() => {
              self.getPosition();
            }, 10000);
          }
          if (self.queued && data.position > 0 && self.position != data.position) {
            self.position = data.position;
            self.events['position'](data);
          }
          break;
        case 'candidate':
          if (self.myConnection.localDescription) {
            let candidate = new RTCIceCandidate(data.candidate);
            self.myConnection.addIceCandidate(candidate);
          }
          break;
        case 'answer':
          self.myConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
          for (var c of self.candidates) {
            this.channel.push('candidate', c);
          }
          break;
        case 'transferToDepartment':
          self.queued = true;
          break;
        case 'reconnectInteraction':
          self.setCurrentInteraction(data.interactionHash);
          if (data.interactionType === "VIDEO") {
            self.events['reconnectVideo'](data);
          } else if (data.interactionType === "AUDIO") {
            self.events['reconnectAudio'](data);
          } else {
            self.events['reconnectInteraction'](data);
          }
          break;
        case 'onlineAgents':
          self.events['onlineAgents'](data);
          break;
      }
    });
    this.channel.on('reconnectInteraction', data => {
      self.setCurrentInteraction(data.interactionHash);
      if (data.interactionType === "VIDEO") {
        self.events['reconnectVideo'](data);
      } else if (data.interactionType === "AUDIO") {
        self.events['reconnectAudio'](data);
      } else {
        self.events['reconnectInteraction'](data);
      }
    });
  },
  on: function (customEvent, callback) {
    if (typeof callback === 'function') {
      this.events[customEvent] = callback;
    }
  },
  setCurrentInteraction: function (interactionHash) {
    this.currentInteraction = interactionHash;
  },
  setCustomer: function (customer) {
    this.customer = customer;
  },
  newInteraction: function (message = null) {
    var msg = {
      customerKey: this.customer && this.customer.customerKey ? this.customer.customerKey : null
    };
    if (message) msg.message = message;
    this.channel.push('newInteraction', msg);
    this.canRoute = true;
  },
  newMessage: function (params) {
    var msg = {
      interactionHash: this.currentInteraction,
      content: params.content,
      url: params.url,
      tempId: params.tempId,
      contentType: params.contentType,
      botAnswerId: params.botAnswerId,
      choice: params.choice
    };
    this.channel.push('newMessage', msg);
  },
  selectDepartment: function (departmentId) {
    var msg = {
      interactionHash: this.currentInteraction,
      departmentId: departmentId
    };
    this.channel.push('selectDepartment', msg);
  },
  interactionInfo: function (type) {
    var msg = {
      type: type,
      accountId: this.accountId,
      interactionHash: this.currentInteraction
    };
    this.lastInteraction = this.currentInteraction;
    this.channel.push('info', msg);
  },
  finishInteraction: function () {
    this.lastInteraction = this.currentInteraction;
    var msg = {
      interactionHash: this.currentInteraction
    };
    this.channel.push('finishInteraction', msg);
    this.closeAgentVideo();
    this.setCurrentInteraction(null);
  },
  deliveredMessage: function (messageId) {
    var msg = {
      messageId: messageId,
      interactionHash: this.currentInteraction,
      accountId: this.accountId
    };
    this.channel.push('deliveredMessage', msg);
  },
  checkBrowserWebRTC() {
    return !!(navigator.getUserMedia || navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia);
  },
  startVideoConnection: function (isVideo, callback) {
    var self = this;
    if (self.checkBrowserWebRTC()) {
      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia;

      var constraints = {
        "audio": true,
        "video": true
      };

      //enabling video and audio channels
      navigator.mediaDevices.getUserMedia(constraints).then(function (s) {
        self.stream = s;

        //inserting our stream to the video tag
        if (isVideo) {
          self.localVideo = document.getElementById('localVideo');
          self.localVideo.srcObject = self.stream;
        }

        //creating our RTCPeerConnection object
        var configuration = {
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };

        self.myConnection = new RTCPeerConnection(configuration);
        self.stream.getTracks().forEach(function (track) {
          self.myConnection.addTrack(track, self.stream);
        });

        self.myConnection.ontrack = function (e) {
          if (isVideo) {
            self.remoteVideo = document.getElementById('remoteVideo');
            self.remoteVideo.srcObject = e.streams[0];
            const remotePlayPromise = self.remoteVideo.play()
            if (remotePlayPromise !== undefined) {
              remotePlayPromise.then(_ => { }).catch(error => { });
            }
            const localPlayPromise = self.localVideo.play()
            if (localPlayPromise !== undefined) {
              localPlayPromise.then(_ => { }).catch(error => { });
            }
          }
        };

        self.myConnection.onicecandidate = function (event) {
          if (event.candidate) {
            self.candidates.push({
              candidate: event.candidate,
              interactionHash: self.currentInteraction,
              accountId: self.accountId
            })
          }
        };

        callback();

      }).catch(function (error) {
        console.error('getUserMedia() error: ', error);
      });

    } else {
      alert("WebRTC is not supported");
    }
  },
  connectAgentVideo: function () {
    var self = this;
    var options = {
      mandatory: {
        OfferToReceiveVideo: true
      }
    };

    self.myConnection.createOffer(options).then(function (offer) {
      const msg = {
        offer: offer,
        interactionHash: self.currentInteraction,
        accountId: self.accountId
      };
      self.channel.push('offer', msg);

      self.myConnection.setLocalDescription(offer);
    }).catch(function (error) {
      alert(error);
    })
  },
  routeInteraction: function () {
    if (this.canRoute) {
      this.channel.push('routeInteraction', this.currentInteraction);
      this.canRoute = false;
    }
  },
  closeAgentVideo: function () {
    if (this.remoteVideo) {
      this.remoteVideo.srcObject = null;
    }
    if (this.localVideo) {
      this.localVideo.srcObject = null;
    }
    if (this.stream) {
      var tracks = this.stream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });

      this.myConnection.close();
      this.myConnection.onicecandidate = null;
      this.myConnection.ontrack = null;
    }
  },
  updateCustomer: function (customer, notify = true) {
    this.setCustomer(customer);
    if (notify) this.channel.push('updateCustomer', this.customer);
  },
  updateVisitor: async function (interactionId) {
    const data = {
      interactionInfo: {}
    }

    data.interactionInfo.ip = await this.getIpIpify();
    data.interactionInfo.browser = this.getBrowser();
    data.interactionInfo.os = this.getOsName();
    data.interactionInfo.source = window.location.href;
    data.interactionInfo.interactionId = interactionId;
    data.interactionInfo.accountId = this.accountId;

    this.channel.push('updateVisitor', data);
  },
  addHistory: function () {
    this.history = [];
    const historyPage = {
      pageUrl: window.location.href,
      pageUrlMin: window.location.pathname,
      dateAccess: new Date().getTime()
    }
    this.history.push(historyPage);

    if (this.history.length > 15) this.history.shift();

    const _array_tojson = Array.prototype.toJSON;
    delete Array.prototype.toJSON;
    const parsed_history = JSON.stringify(this.history);
    Array.prototype.toJSON = _array_tojson;
  },
  getBrowser: function () {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browser = navigator.appName;
    var version = '' + parseFloat(nVer);
    var nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    }
    // Edge
    else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 5);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    return `${browser} ${version}`;
  },
  getOsName: function () {
    var OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    return OSName;
  },
  getIpIpify: function () {
    return axios.get('https://api.ipify.org').then(response => response.data).catch(() => "");
  },
}

if (!window.omzVish) {
  window.omzVish = new OmzVish();
}
