import React from 'react';
import PropTypes from 'prop-types';

import Bubble from '../../../Atoms/Bubble';
import File from '../../Medias/File';
import './index.css';

const MessageFile = ({ content, isFromAgent }) => {
	const { createdAt, url } = content;
	const classes = ['MessageFile'];

	if (!isFromAgent) classes.push('MessageFile--client');

	return (
		<div className={classes.join(' ')}>
			<a
				className="MessageFile__link"
				href={url}
				target="_blank"
				download
				rel="noopener noreferrer"
			>
				<Bubble isFromAgent={isFromAgent} time={createdAt}>
					<File isFromAgent={isFromAgent} />
				</Bubble>
			</a>
		</div>
	);
};

MessageFile.propTypes = {
	content: PropTypes.shape({
		createdAt: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	}).isRequired,
	isFromAgent: PropTypes.bool.isRequired
};

export default MessageFile;
