import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	error: null,
	loading: false,
	onlineAgents: {}
};

const fetchAppearanceStart = (state) => updateObject(state,
	{ loading: true });

const fetchAppearanceSuccess = (state, { payload }) => updateObject(state,
	{ data: payload, loading: false });

const fetchAppearanceFail = (state, { error }) => updateObject(state,
	{ error, loading: false });

const fetchedOnlineAgentsSuccess = (state, action) => updateObject(state,
	{ onlineAgents: action.payload });

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.FETCH_APPEARANCE_BEGIN: return fetchAppearanceStart(state);
	case actionTypes.FETCH_APPEARANCE_SUCCESS: return fetchAppearanceSuccess(state, action);
	case actionTypes.FETCH_APPEARANCE_FAIL: return fetchAppearanceFail(state, action);
	case actionTypes.FETCHED_ONLINE_AGENTS_SUCCESS: return fetchedOnlineAgentsSuccess(state, action);
	default:
		return state;
	}
};

export default reducer;
