import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
	createStore,
	compose,
	combineReducers,
	applyMiddleware
} from 'redux';
import { IntlProvider } from 'react-intl';

import './index.css';
import App from './components/App';
import appearanceReducer from './store/reducers/appearance';
import messageReducer from './store/reducers/message';
import interactionReducer from './store/reducers/interaction';
import interfaceReducer from './store/reducers/interface';
import * as serviceWorker from './serviceWorker';

if (!Intl.PluralRules) {
	require('intl-pluralrules/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
}

const getQueryStringValue = (key) => decodeURIComponent(
	window.location.search.replace(
		new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[.+*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'
	)
);

let messages;
const language = getQueryStringValue('locale') || 'pt';
try {
	messages = require(`./locales/${window.location.host.replace('.omnize.com.br', '')}.json`);
	if (messages) {
		messages = messages[language];
	}
} catch (e) {
	messages = require(`./locales/${language}.json`);
}

const composeEnhancers = ['development', 'local'].includes(process.env.NODE_ENV) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const rootReducer = combineReducers({
	appearance: appearanceReducer,
	interaction: interactionReducer,
	message: messageReducer,
	interface: interfaceReducer
});

const store = createStore(rootReducer, composeEnhancers(
	applyMiddleware(thunk)
));

const app = (
	<IntlProvider locale="pt" messages={messages}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</IntlProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
