import React from 'react';
import PropTypes from 'prop-types';
import ImgSvg from '../../Atoms/ImgSvg';
import ButtonIcon from '../../Atoms/Buttons/ButtonIcon';
import './index.css';

const Minimize = ({ className }) => {
	const sendMessage = () => {
		window.top.postMessage('omzButtonClick', '*');
	};

	return (
		<ButtonIcon
			className={`Minimize ${className}`}
			fill="transparent"
			svg={<ImgSvg name="chevron-down" />}
			click={sendMessage}
		/>
	);
};

Minimize.propTypes = {
	className: PropTypes.string
};

Minimize.defaultProps = {
	className: ''
};

export default Minimize;
