import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const RateNps = ({ options, onSelect }) => (
	<div className="RateNps">
		{options.map((option, indexOption) => (
			<span className="RateNps__item" key={`RateNps-${option.value}`}>
				<input
					type="radio"
					name="value"
					id={indexOption}
					className="RateNps__input"
					value={option.value}
					onChange={() => onSelect(option.value)}
				/>
				<label htmlFor={indexOption} className="RateNps__label">
					{option.label}
				</label>
			</span>
		))}
	</div>
);

RateNps.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
			label: PropTypes.string.isRequired
		})
	),
	onSelect: PropTypes.func
};

RateNps.defaultProps = {
	options: [],
	onSelect: () => {}
};

export default RateNps;
