import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Container = ({ children }) => (
	<div className="Container">
		{children}
	</div>
);

Container.propTypes = {
	children: PropTypes.element.isRequired
};

export default Container;
