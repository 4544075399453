import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Route,
	Switch,
	withRouter,
	Redirect
} from 'react-router-dom';

import * as actions from '../../store/actions/index';
import { findGetParameter } from '../../shared/utility';

import Container from '../Atoms/Container';
import Loader from '../Atoms/Loader';
import InitialPage from '../Pages/InitialPage';
import ChatPage from '../Pages/ChatPage';
import ErrorPage from '../Pages/ErrorPage';

import '../../assets/omz-vish';
import TransferPage from '../Pages/TransferPage';

class App extends Component {
	componentDidMount() {
		this.checkForAccountId();
		this.addVishEvents();
	}

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const { location, fetchAppearance, history } = this.props;
		let { search } = location;
		if (!search) {
			search = window.omzVish.url.search;
		}
		const reloadAppearance = findGetParameter(search, 'reloadAppearance');

		if (reloadAppearance) {
			const accountId = findGetParameter(search, 'id');

			if (accountId) {
				fetchAppearance(accountId);
				window.omzVish.connect(accountId);
			}

			history.push('/');
		}
	}

	checkForAccountId = () => {
		const { history, location, fetchAppearance } = this.props;
		let { search } = location;
		if (!search) {
			search = window.omzVish.url.search;
		}
		const accountId = findGetParameter(search, 'id');

		if (!accountId) {
			window.omzVish.ready = true;
			history.push('/error');
		} else {
			fetchAppearance(accountId);
			window.omzVish.connect(accountId);
		}
	}

	addVishEvents = () => {
		const {
			updateInteraction,
			finishedInteraction,
			onTransferInteraction,
			loadMessages,
			fetchedOnlineAgents,
			clearInteraction,
			clearMessages,
			history
		} = this.props;

		window.omzVish.on('unavailable', (data) => {
			updateInteraction(data);
		});

		window.omzVish.on('acceptedInteraction', (data) => {
			updateInteraction(data);
			history.push('/chat');
		});

		window.omzVish.on('reconnectedInteraction', (data) => {
			const { interactionHash } = data;
			updateInteraction(data);
			loadMessages(interactionHash);
			history.push('/chat');
		});

		window.omzVish.on('newMessage', (receivedMessage) => {
			const { interaction, newMessage } = this.props;
			const { data = {} } = interaction;
			const { agent = {} } = data;
			const { name, photo } = agent;

			newMessage(receivedMessage, { name, photo });
			updateInteraction({ ...data, typing: false });
		});

		window.omzVish.on('receivedMessage', (data) => {
			const { updateMessage } = this.props;

			updateMessage({ ...data, state: 'RECEIVED' });
		});

		window.omzVish.on('finishedInteraction', () => {
			finishedInteraction();
		});

		window.omzVish.on('transferring', (data) => {
			onTransferInteraction(data);
			history.push('/transfer');
		});

		window.omzVish.on('createdInteraction', (newInteraction) => {
			updateInteraction(newInteraction);

			const { interaction, selectDepartment } = this.props;
			const { data = {} } = interaction;
			const { departmentId, customer = {} } = data;
			const { fields } = customer;

			if (!fields) return;
			if (departmentId) selectDepartment(departmentId);
		});

		window.omzVish.on('on_hold', (data) => {
			const interaction = { ...data };
			delete (interaction.type);

			updateInteraction({ ...interaction });
		});

		window.omzVish.on('typing', (data) => {
			const interaction = { ...data };
			delete (interaction.type);

			updateInteraction({ ...interaction, typing: true });
		});

		window.omzVish.on('cleared', (data) => {
			const interaction = { ...data };
			delete (interaction.type);

			updateInteraction({ ...interaction, typing: false });
		});

		window.omzVish.on('onlineAgents', (data) => {
			fetchedOnlineAgents(data);
		});

		window.omzVish.on('invalidInteraction', () => {
			clearInteraction();
			clearMessages();
			history.push('/');
		});

		window.omzVish.on('unrecognizedError', () => {
			clearInteraction();
			clearMessages();
			history.push('/');
		});

		window.omzVish.on('updatedCustomer', (customer) => {
			updateInteraction({ customer: { ...customer } });
		});

		window.omzVish.on('notUpdatedCustomer', () => {});
	}

	render() {
		const routes = (
			<Switch>
				<Route path="/error" exact component={ErrorPage} />
				<Route path="/chat" component={ChatPage} />
				<Route path="/transfer" component={TransferPage} />
				<Route path="/" exact component={InitialPage} />
				<Redirect to="/" />
			</Switch>
		);
		let primaryColor = '#000000';
		const { appearance } = this.props;
		if (appearance.data) primaryColor = appearance.data.jsonAppearance.preferences.baseColor;

		const scriptStyles = (
			<style>
				{`:root, ::before {
					--color-primary: ${primaryColor};
					--color-white: #ffffff;
					--color-grey-light-1: #fafafa;
					--color-grey-light-2: #edeef0;
					--color-grey-light-3: #bdbdbd;
					--color-grey-light-4: #adadad;
					--color-grey-dark-1: #757575;
					--color-grey-dark-2: #616161;
					--color-green: #7ed321;
					--color-red: #ea4143;
					--color-yellow: #f5a623;
					--color-red-dark: #f11514;
					--color-blue: #4a90e2;
					--color-orange: #f5a623;
					--color-black-5: rgba(0, 0, 0, 0.5);
					--color-white-7: rgba(255, 255, 255, 0.7);
					--shadow-bottom-dark: 0 2rem 1.5rem -2rem var(--color-grey-light-3);
					--shadow-light: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
					--shadow-big: 0 0 25px 0 rgba(0, 0, 0, 0.25);
					--shadow-smooth: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
				}`}
			</style>
		);

		if (!appearance || appearance.loading) {
			return null;
		}

		return (
			<>
				<Container>
					{window.omzVish.ready ? (
						<>
							{routes}
						</>
					) : (
						<Loader />
					)}
				</Container>
				{scriptStyles}
			</>
		);
	}
}

App.propTypes = {
	location: PropTypes.shape().isRequired,
	history: PropTypes.shape().isRequired,
	interaction: PropTypes.shape().isRequired,
	appearance: PropTypes.shape().isRequired,
	fetchAppearance: PropTypes.func.isRequired,
	updateInteraction: PropTypes.func.isRequired,
	newMessage: PropTypes.func.isRequired,
	updateMessage: PropTypes.func.isRequired,
	finishedInteraction: PropTypes.func.isRequired,
	onTransferInteraction: PropTypes.func.isRequired,
	loadMessages: PropTypes.func.isRequired,
	fetchedOnlineAgents: PropTypes.func.isRequired,
	clearInteraction: PropTypes.func.isRequired,
	clearMessages: PropTypes.func.isRequired,
	selectDepartment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	appearance: state.appearance,
	interaction: state.interaction
});

const mapActionsToProps = (dispatch) => ({
	fetchAppearance: (accountId) => dispatch(actions.fetchAppearance(accountId)),
	updateInteraction: (interaction) => dispatch(actions.updateInteraction(interaction)),
	newMessage: (message, agentInfo) => dispatch(actions.newMessage(message, agentInfo)),
	updateMessage: (message) => dispatch(actions.updateMessage(message)),
	finishedInteraction: () => dispatch(actions.finishedInteraction()),
	onTransferInteraction: (data) => dispatch(actions.transferringInteraction(data)),
	loadMessages: (hash) => dispatch(actions.loadMessages(hash)),
	fetchedOnlineAgents: (data) => dispatch(actions.fetchedOnlineAgents(data)),
	clearInteraction: () => dispatch(actions.clearInteraction()),
	clearMessages: () => dispatch(actions.clearMessages()),
	selectDepartment: (departmentId) => dispatch(actions.selectDepartment(departmentId))
});

export default withRouter(connect(mapStateToProps, mapActionsToProps)(App));
