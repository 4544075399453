import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

import Loader from '../../Loader';

const Button = ({
	className,
	loading,
	children,
	fill,
	disabled,
	onClick
}) => {
	let buttonColor;

	switch (fill) {
	case 'red':
		buttonColor = { backgroundColor: 'var(--color-red)' };
		break;
	case 'green':
		buttonColor = { backgroundColor: 'var(--color-green)' };
		break;
	case 'transparent':
		buttonColor = {
			backgroundColor: 'transparent',
			color: 'var(--color-grey-dark-3)'
		};
		break;
	default:
		buttonColor = { backgroundColor: 'var(--color-primary)' };
	}

	return (
		<button
			style={buttonColor}
			className={`Button ${className}`}
			type="button"
			onClick={(e) => {
				if (!loading) onClick(e);
			}}
			disabled={disabled}
		>
			{loading ? <Loader fill="white" /> : children}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	loading: PropTypes.bool,
	fill: PropTypes.oneOf(['red', 'green', 'primary', 'transparent']),
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

Button.defaultProps = {
	className: '',
	loading: false,
	fill: 'primary',
	disabled: false,
	onClick: () => {}
};

export default Button;
