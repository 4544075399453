import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const HeadingPrimary = ({ info }) => (
	<h1 className="heading heading--primary">
		{info}
	</h1>
);

HeadingPrimary.propTypes = {
	info: PropTypes.string.isRequired
};

export default HeadingPrimary;
