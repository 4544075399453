import React from 'react';
import PropTypes from 'prop-types';

import ImgSvg from '../../ImgSvg';

import './index.css';

const onType = (e, click) => {
	if (e.key === 'Enter') {
		click();
	}
};

const InputText = ({
	type,
	placeholder,
	isRequired,
	value,
	valid,
	click,
	disabled,
	onBlur,
	onChange
}) => {
	const classNames = ['Input'];
	let alertIcon = null;

	if (valid === true) {
		classNames.push('Input--valid');
		alertIcon = <ImgSvg className="Input__status-icon Input__valid-icon" name="check" />;
	} else if (valid === false) {
		classNames.push('Input--invalid');
		alertIcon = <ImgSvg className="Input__status-icon Input__invalid-icon" name="x" />;
	}

	return (
		<div className={classNames.join(' ')}>
			<input
				className="Input__input"
				type={type}
				placeholder={isRequired ? placeholder.concat('*') : placeholder}
				required={isRequired}
				value={value}
				onBlur={onBlur}
				disabled={disabled}
				onChange={(e) => {
					onType(e, click);
					onChange(e.target.value);
				}}
			/>
			{alertIcon}
		</div>
	);
};

InputText.propTypes = {
	type: PropTypes.oneOf(['text', 'number', 'email']),
	placeholder: PropTypes.string,
	isRequired: PropTypes.bool,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	valid: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf([null])
	]),
	click: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func
};

InputText.defaultProps = {
	type: 'text',
	placeholder: '',
	isRequired: false,
	disabled: false,
	value: '',
	valid: null,
	click: () => {},
	onBlur: () => {},
	onChange: () => {}
};

export default InputText;
