import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Atoms/Buttons/Button';
import ImgSvg from '../../../Atoms/ImgSvg';

import './index.css';

const RateMood = ({ options, onSelect }) => {
	const icons = ['good', 'bad'];

	return (
		<div className="RateMood">
			{options.map((option, indexOption) => (
				<Button
					fill="transparent"
					key={`RateMood-${option.value}`}
					onClick={() => onSelect(option.value)}
				>
					<input
						type="radio"
						name="value"
						id={indexOption}
						className="RateMood__input"
						value={option.value}
					/>
					<label
						htmlFor={indexOption}
						className={`RateMood__label RateMood__label--${icons[indexOption]}`}
					>
						<ImgSvg name={icons[indexOption]} />
						<p>{option.label}</p>
					</label>
				</Button>
			))}
		</div>
	);
};

RateMood.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired
		})
	).isRequired,
	onSelect: PropTypes.func
};

RateMood.defaultProps = {
	onSelect: () => {}
};

export default RateMood;
