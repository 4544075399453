import React from 'react';
import PropTypes from 'prop-types';

import './index.css';
import ImgSvg from '../ImgSvg';

const AvatarName = ({
	className, name, url, status, showStatus, selected
}) => {
	const classes = ['AvatarName', className];
	let avatarContent = <ImgSvg name="user" />;

	const names = name ? name.split(' ') : [];
	if (names.length) avatarContent = `${name[0]}`;

	if (url) avatarContent = <img src={url} alt="Agent" />;

	if (showStatus) {
		classes.push('AvatarName--status');

		switch (status) {
		case 'REGISTERED':
		case 'ONLINE':
			classes.push('AvatarName--online');
			break;

		case 'REGISTERING':
		case 'UNREGISTERING':
			classes.push('AvatarName--connecting');
			break;

		default:
			classes.push('AvatarName--offline');
		}
	}

	if (selected) classes.push('AvatarName--selected');

	return (
		<div className={classes.join(' ')}>
			<div className="AvatarName__info">
				{avatarContent}
			</div>
		</div>
	);
};

AvatarName.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	url: PropTypes.string,
	status: PropTypes.oneOf(['NOT_REGISTERED', 'REGISTERING', 'REGISTERED', 'UNREGISTERING']),
	showStatus: PropTypes.bool,
	selected: PropTypes.bool
};

AvatarName.defaultProps = {
	className: '',
	name: null,
	url: null,
	status: 'NOT_REGISTERED',
	showStatus: false,
	selected: false
};

export default AvatarName;
