import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';

class ChatInputText extends Component {
	constructor(props) {
		super(props);

		this.input = React.createRef();
		this.blocked = false;
	}

	onType = (e) => {
		const inputValue = this.input.current.innerText;
		const invalidEmptyKeys = [32, 13];

		if (invalidEmptyKeys.includes(e.keyCode) && inputValue.length === 0) {
			this.blocked = true;
			e.preventDefault();
			e.stopPropagation();
		}
	};

	onChange = (e) => {
		if (!this.blocked) {
			const { onChange, onSend } = this.props;
			const inputValue = this.input.current.innerText;

			onChange(inputValue);
			if (e.key === 'Enter') {
				onSend();
			}
		} else {
			this.blocked = false;
		}
	}

	placeCaretAtEnd = (el) => {
		el.focus();
		if (typeof window.getSelection !== 'undefined'
				&& typeof document.createRange !== 'undefined') {
			const range = document.createRange();
			range.selectNodeContents(el);
			range.collapse(false);
			const sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
		} else if (typeof document.body.createTextRange !== 'undefined') {
			const textRange = document.body.createTextRange();
			textRange.moveToElementText(el);
			textRange.collapse(false);
			textRange.select();
		}
	}

	render() {
		const { disabled, inputValue, placeholder } = this.props;

		if (this.input.current && this.input.current.innerText !== inputValue) {
			this.input.current.innerHTML = inputValue;
			this.placeCaretAtEnd(this.input.current);
		}

		return (
			<span className={`ChatInputText ${disabled ? 'ChatInputText--disabled' : ''}`}>
				<div
					onKeyDown={this.onType}
					onKeyUp={this.onChange}
					className="ChatInputText__input"
					contentEditable={!disabled}
					placeholder={placeholder}
					ref={this.input}
					role="textbox"
					tabIndex="0"
					aria-labelledby="Chat input text"
				/>
			</span>
		);
	}
}

ChatInputText.propTypes = {
	disabled: PropTypes.bool,
	inputValue: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onSend: PropTypes.func
};

ChatInputText.defaultProps = {
	disabled: false,
	inputValue: '',
	placeholder: 'Escreva aqui',
	onChange: () => {},
	onSend: () => {}
};

export default ChatInputText;
