import React from 'react';
import PropTypes from 'prop-types';
import HeadingPrimary from '../../Atoms/Headers/HeadingPrimary';
import './index.css';

const WelcomeMessage = ({ header, paragraph }) => (
	<div className="WelcomeMessage">
		<HeadingPrimary info={header} />
		<p>{paragraph}</p>
	</div>
);

WelcomeMessage.propTypes = {
	header: PropTypes.string,
	paragraph: PropTypes.string
};

WelcomeMessage.defaultProps = {
	header: '',
	paragraph: ''
};

export default WelcomeMessage;
