import React from 'react';
import PropTypes from 'prop-types';

import AvatarName from '../../Atoms/AvatarName';
import AvatarQuantity from '../../Atoms/AvatarQuantity';
import './index.css';

const AvatarNameList = ({
	agentList, showStatus, status, small
}) => {
	const classes = ['AvatarNameList'];
	if (showStatus) {
		classes.push('AvatarNameList--status');
		switch (status) {
		case 'REGISTERED':
		case 'ONLINE':
			classes.push('AvatarNameList--online');
			break;

		case 'REGISTERING':
		case 'UNREGISTERING':
			classes.push('AvatarNameList--connecting');
			break;

		default:
			classes.push('AvatarNameList--offline');
		}
	}

	if (small) classes.push('AvatarNameList--small');

	return (
		<div className={classes.join(' ')}>
			{agentList.map((avatar, index) => {
				const { photo, name } = avatar;
				if (index === 3) return <AvatarQuantity key={`AvatarQuantity-${name}`} quantity={agentList.length - index} />;
				return index < 3 ? <AvatarName key={`AvatarName-${name}`} url={photo} name={name} /> : null;
			})}
		</div>
	);
};

AvatarNameList.propTypes = {
	agentList: PropTypes.arrayOf(PropTypes.shape({
		photo: PropTypes.string
	})).isRequired,
	showStatus: PropTypes.bool,
	status: PropTypes.oneOf(['ONLINE', 'OFFLINE']),
	small: PropTypes.bool
};

AvatarNameList.defaultProps = {
	showStatus: false,
	status: 'OFFLINE',
	small: false
};

export default AvatarNameList;
