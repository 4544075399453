import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const ButtonIcon = ({
	className,
	svg,
	fill,
	click,
	circle,
	disabled
}) => {
	let buttonColor;
	const classes = ['ButtonIcon', className];

	switch (fill) {
	case 'red':
		buttonColor = { backgroundColor: 'var(--color-red)' };
		break;
	case 'green':
		buttonColor = { backgroundColor: 'var(--color-green)' };
		break;
	case 'grey':
		buttonColor = { backgroundColor: 'var(--color-grey-dark-2)' };
		break;
	case 'white':
		buttonColor = { backgroundColor: 'var(--color-white)' };
		break;
	default:
		buttonColor = { backgroundColor: 'var(--color-transparent)' };
	}

	if (circle) {
		classes.push('ButtonIcon--circle');
	}

	if (disabled) {
		classes.push('ButtonIcon--disabled');
	}

	return (
		<button
			type="button"
			style={buttonColor}
			className={classes.join(' ')}
			onClick={click}
		>
			{svg}
		</button>
	);
};

ButtonIcon.propTypes = {
	className: PropTypes.string,
	svg: PropTypes.element.isRequired,
	fill: PropTypes.oneOf(['transparent', 'grey', 'red', 'green']),
	circle: PropTypes.bool,
	disabled: PropTypes.bool,
	click: PropTypes.func
};

ButtonIcon.defaultProps = {
	className: '',
	fill: 'transparent',
	circle: false,
	disabled: false,
	click: () => {}
};

export default ButtonIcon;
