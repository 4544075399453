import * as actionTypes from '../actions/actionTypes';
import { updateObject, getObjectWithValues } from '../../shared/utility';

const initialState = {
	data: {},
	loading: false,
	copy: {
		success: false,
		loading: false,
		error: ''
	}
};

const onLoading = (state) => updateObject(state, {
	loading: true
});

const newInteractionBegin = (state, action) => updateObject(state, {
	loading: true,
	data: {
		customer: action.payload.customer,
		departmentId: action.payload.departmentId
	}
});

const newInteraction = (state, action) => updateObject(state, {
	loading: false,
	data: {
		interactionHash: action.payload.interactionHash,
		customer: action.payload.customer
	}
});

const selectDepartment = (state, action) => updateObject(state, {
	data: {
		...state.data,
		departmentId: action.payload,
		currentState: 'QUEUED'
	}
});

const updateInteraction = (state, action) => updateObject(state, {
	loading: false,
	data: {
		...state.data,
		...getObjectWithValues(action.payload)
	}
});

const finishInteraction = (state) => updateObject(state, {
	loading: false,
	data: {
		...state.data,
		currentState: 'ENDED'
	}
});

const clearInteraction = (state) => updateObject(state, {
	loading: false,
	data: {}
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.NEW_INTERACTION_BEGIN: return newInteractionBegin(state, action);
	case actionTypes.NEW_INTERACTION_SUCCESS: return newInteraction(state, action);
	case actionTypes.SELECT_DEPARTMENT_BEGIN: return selectDepartment(state, action);
	case actionTypes.UPDATE_INTERACTION_SUCCESS: return updateInteraction(state, action);
	case actionTypes.FINISH_INTERACTION_BEGIN: return onLoading(state);
	case actionTypes.FINISH_INTERACTION_SUCCESS: return finishInteraction(state);
	case actionTypes.CLEAR_INTERACTION_SUCCESS: return clearInteraction(state, action);
	case actionTypes.TRANSFERRING_INTERACTION: return updateObject(state, {
		...state.interaction,
		data: {
			...action.payload,
			reconnected: true
		}
	});
	case actionTypes.SEND_INTERACTION_COPY_BEGIN: return updateObject(state, {
		...state.interaction,
		copy: {
			loading: true,
			success: false,
			error: ''
		}
	});
	case actionTypes.SEND_INTERACTION_COPY_SUCCESS: return updateObject(state, {
		...state.interaction,
		copy: {
			loading: false,
			success: true,
			error: ''
		}
	});
	case actionTypes.SEND_INTERACTION_COPY_FAIL: return updateObject(state, {
		...state.interaction,
		copy: {
			loading: false,
			success: false,
			error: action.payload
		}
	});
	default: return state;
	}
};

export default reducer;
