import * as actionTypes from '../actions/actionTypes';
import { updateObject, setRequestStatus, parseMessages } from '../../shared/utility';

const initialState = {
	messages: []
};

const sendMessageSuccess = (state, action) => {
	let { message } = action;
	message = parseMessages(message);
	const updatedMessages = state.messages.concat(message);

	return updateObject(state, {
		messages: updatedMessages
	});
};

const newMessageSuccess = (state, action) => {
	const { payload } = action;
	return updateObject(state, {
		messages: [...state.messages, { ...parseMessages(payload), source: 'agent' }]
	});
};

const updateMessageSuccess = (state, action) => {
	const { payload } = action;

	return updateObject(state, {
		messages: state.messages.map((msg) => {
			if (msg.tempId === payload.tempId) {
				return { ...msg, ...payload };
			}

			return { ...msg };
		})
	});
};

const clearMessagesSuccess = (state) => updateObject(state, { messages: [] });

const fetchInteractionMessages = (state, action) => {
	const { payload = [] } = action;
	return updateObject(state, {
		messages: payload.map((message) => parseMessages(message))
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.NEW_MESSAGE_SUCCESS:
		return newMessageSuccess(state, action);
	case actionTypes.UPDATE_MESSAGE_SUCCESS:
		return updateMessageSuccess(state, action);
	case actionTypes.SEND_MESSAGE_SUCCESS:
		return sendMessageSuccess(state, action);
	case actionTypes.CLEAR_MESSAGES_SUCCESS:
		return clearMessagesSuccess(state);
	case actionTypes.LOAD_MESSAGES_BEGIN: return updateObject(state, {
		fetchInteractionMessages: setRequestStatus()
	});
	case actionTypes.LOAD_MESSAGES_SUCCESS: return fetchInteractionMessages(state, action);
	case actionTypes.LOAD_MESSAGES_FAIL: return updateObject(state, {
		fetchInteractionMessages: setRequestStatus('fail', action.error)
	});
	default:
		return state;
	}
};

export default reducer;
