import React from 'react';

import './index.css';
import ImgSvg from '../../Atoms/ImgSvg';

const TransferTemplate = () => (
	<div className="TransferTemplate">
		<div className="TransferTemplate__icon">
			<ImgSvg name="transfer" />
		</div>
		<p>Transferindo atendimento</p>
	</div>
);

export default TransferTemplate;
