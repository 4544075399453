import React from 'react';
import PropTypes from 'prop-types';

import './index.css';
import LoaderBlack from '../../../assets/images/gif/loader_black.gif';
import LoaderWhite from '../../../assets/images/gif/loader_white.gif';

const Loader = ({ size, fill }) => {
	const classes = ['Loader__img'];
	let loader = LoaderBlack;
	if (size === 'small') classes.push('Loader__img--small');
	if (fill === 'white') loader = LoaderWhite;

	return (
		<div className="Loader">
			<img
				className={classes.join(' ')}
				src={loader}
				alt="loader"
			/>
		</div>
	);
};

Loader.propTypes = {
	size: PropTypes.oneOf(['small', 'medium']),
	fill: PropTypes.oneOf(['white', 'black'])
};

Loader.defaultProps = {
	size: 'medium',
	fill: 'black'
};

export default Loader;
