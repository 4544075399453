import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

class SlideIn extends React.Component {
	state = {
		shouldRender: false
	};

	componentDidMount() {
		const { delay } = this.props;
		this.timeout = setTimeout(() => {
			this.setState({ shouldRender: true });
		}, delay);
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	render() {
		const { children } = this.props;
		const { shouldRender } = this.state;

		return (
			shouldRender && (
				<div className="SlideIn">
					{children}
				</div>
			)
		);
	}
}

SlideIn.propTypes = {
	delay: PropTypes.number
};

SlideIn.defaultProps = {
	delay: 300
};

export default SlideIn;
