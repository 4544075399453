import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import Bubble from '../../../Atoms/Bubble';
import './index.css';

const MessageText = ({ content, isFromAgent }) => {
	const { createdAt } = content;
	const classes = ['MessageText'];

	if (!isFromAgent) classes.push('MessageText--client');

	return (
		<div className={classes.join(' ')}>
			<Bubble
				isFromAgent={isFromAgent}
				time={createdAt}
			>
				{ReactHtmlParser(content.content)}
			</Bubble>
		</div>
	);
};

MessageText.propTypes = {
	content: PropTypes.shape({
		content: PropTypes.string,
		createdAt: PropTypes.string
	}).isRequired,
	isFromAgent: PropTypes.bool.isRequired
};

export default MessageText;
