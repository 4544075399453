import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

import SlideIn from '../SlideIn';

const Box = ({ className, delay, children }) => (
	<SlideIn delay={delay}>
		<div className={`Box ${className}`}>
			{children}
		</div>
	</SlideIn>
);

Box.propTypes = {
	className: PropTypes.string,
	delay: PropTypes.number
};

Box.defaultProps = {
	className: '',
	delay: 100
};

export default Box;
