export const FETCH_APPEARANCE_BEGIN = 'FETCH_APPEARANCE_BEGIN';
export const FETCH_APPEARANCE_SUCCESS = 'FETCH_APPEARANCE_SUCCESS';
export const FETCH_APPEARANCE_FAIL = 'FETCH_APPEARANCE_FAIL';

export const NEW_INTERACTION_BEGIN = 'NEW_INTERACTION_BEGIN';
export const NEW_INTERACTION_SUCCESS = 'NEW_INTERACTION_SUCCESS';

export const LOAD_MESSAGES_BEGIN = 'LOAD_MESSAGES_BEGIN';
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAIL = 'LOAD_MESSAGES_FAIL';

export const UPDATE_INTERACTION_SUCCESS = 'UPDATE_INTERACTION_SUCCESS';

export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const NEW_MESSAGE_SUCCESS = 'NEW_MESSAGE_SUCCESS';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const CLEAR_MESSAGES_SUCCESS = 'CLEAR_MESSAGES_SUCCESS';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';

export const TRANSFERRING_INTERACTION = 'TRANSFERRING_INTERACTION';

export const FINISH_INTERACTION_BEGIN = 'FINISH_INTERACTION_BEGIN';
export const FINISH_INTERACTION_SUCCESS = 'FINISH_INTERACTION_SUCCESS';

export const CLEAR_INTERACTION_SUCCESS = 'CLEAR_INTERACTION_SUCCESS';

export const ADD_WELCOME_MESSAGE = 'ADD_WELCOME_MESSAGE';
export const ADD_DEPARTMENT_MESSAGE = 'ADD_DEPARTMENT_MESSAGE';

export const SELECT_DEPARTMENT_BEGIN = 'SELECT_DEPARTMENT_BEGIN';
export const SELECT_DEPARTMENT_SUCCESS = 'SELECT_DEPARTMENT_SUCCESS';

export const FETCHED_ONLINE_AGENTS_SUCCESS = 'FETCHED_ONLINE_AGENTS_SUCCESS';

export const CUSTOMER_FEEDBACK_BEGIN = 'CUSTOMER_FEEDBACK_BEGIN';
export const CUSTOMER_FEEDBACK_SUCCESS = 'CUSTOMER_FEEDBACK_SUCCESS';
export const CUSTOMER_FEEDBACK_FAIL = 'CUSTOMER_FEEDBACK_FAIL';
export const CUSTOMER_FEEDBACK_SKIP = 'CUSTOMER_FEEDBACK_SKIP';

export const TOGGLE_VIDEO = 'TOGGLE_VIDEO';
export const CLOSE_VIDEO = 'CLOSE_VIDEO';

export const SEND_INTERACTION_COPY_BEGIN = 'SEND_INTERACTION_COPY_BEGIN';
export const SEND_INTERACTION_COPY_SUCCESS = 'SEND_INTERACTION_COPY_SUCCESS';
export const SEND_INTERACTION_COPY_FAIL = 'SEND_INTERACTION_COPY_FAIL';
