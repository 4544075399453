import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const WaitMedia = ({ waitMediaUrl }) => (
	<div className="WaitMedia" data-testid="component-waitMedia">
		<div className="OpacityBG" />
		<div className="ImageContainer">
			<img src={waitMediaUrl} alt="" />
		</div>
	</div>
);

WaitMedia.propTypes = {
	waitMediaUrl: PropTypes.string
};

WaitMedia.defaultProps = {
	waitMediaUrl: ''
};

export default WaitMedia;
