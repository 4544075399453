import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Card = ({ title, children }) => (
	<div className="Card">
		{title && (
			<h2 className="Card__title">
				{title}
			</h2>
		)}
		{children}
	</div>
);

Card.propTypes = {
	title: PropTypes.string
};

Card.defaultProps = {
	title: ''
};

export default Card;
