import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const AvatarQuantity = ({ quantity }) => (
	<div className="AvatarQuantity">
		<span className="AvatarQuantity__plus">
			+
			{quantity}
		</span>
	</div>
);

AvatarQuantity.propTypes = {
	quantity: PropTypes.number.isRequired
};

export default AvatarQuantity;
