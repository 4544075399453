import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import './index.css';
import ImgSvg from '../../Atoms/ImgSvg';

class Attachment extends Component {
	constructor(props) {
		super(props);
		this.inputFile = createRef();
	}

	onAttachment = () => {
		const { onSendAttachment } = this.props;
		const formData = new FormData();
		formData.append('files[]', this.inputFile.current.files[0]);

		if (this.inputFile.current.files[0]) {
			const { name, type, size } = this.inputFile.current.files[0];
			const fileInfo = { name, size, type };
			onSendAttachment({ formData, fileInfo });
		}
	}

	render() {
		const { disabled } = this.props;

		return (
			<label
				className={`Attachment__label ${disabled ? 'Attachment__label--disabled' : ''}`}
				htmlFor="files"
			>
				<input
					type="file"
					id="files"
					className="Attachment__input"
					disabled={disabled}
					onChange={this.onAttachment}
					ref={this.inputFile}
				/>
				<ImgSvg name="attach" />
			</label>
		);
	}
}

Attachment.propTypes = {
	disabled: PropTypes.bool,
	onSendAttachment: PropTypes.func.isRequired
};

Attachment.defaultProps = {
	disabled: false
};

export default Attachment;
