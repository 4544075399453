import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css';

import * as actions from '../../../store/actions';
import { getFirstAndLastName, departmentHasChannel } from '../../../shared/utility';
import browserInfo from '../../../shared/browserInfo';

import AvatarName from '../../Atoms/AvatarName';
import ImgSvg from '../../Atoms/ImgSvg';
import ButtonIcon from '../../Atoms/Buttons/ButtonIcon';
import AvatarNameList from '../../Molecules/AvatarNameList';
import ResponseTime from '../../Molecules/ResponseTime';
import FinishModal from '../../Molecules/FinishModal';
import RateModal from '../../Molecules/RateModal';
import SendChatCopyModal from '../../Molecules/SendChatCopyModal';
import Minimize from '../../Molecules/Minimize';

class ChatHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFinishModal: false,
			showRateModal: false,
			showSendChatCopyModal: false
		};
	}

	canEnableVideo = ({
		agent, currentState, listDepartmentWrapper, departmentId
	}) => (
		agent && currentState === 'TALKING'
		&& departmentHasChannel(listDepartmentWrapper, departmentId, 'VIDEO')
	);

	componentDidUpdate = () => {
		const { appearance } = this.props;
		const { listDepartmentWrapper = [] } = appearance;
		const { autostartWebcam = false } = appearance.jsonAppearance.preferences;

		if (autostartWebcam) {
			const {
				agent,
				interaction,
				showVideo,
				toggleVideo
			} = this.props;
			const {
				currentState,
				departmentId
			} = interaction;
			const shouldOpenVideo = (
				this.canEnableVideo({
					agent, currentState, listDepartmentWrapper, departmentId
				}) && !showVideo
			);
			if (shouldOpenVideo) {
				toggleVideo();
			}
		}
	}

	onBack = () => {
		this.checkWhichModalToOpen();
	}

	checkWhichModalToOpen = () => {
		const { interaction } = this.props;
		const { currentState = '' } = interaction;

		if (currentState.toUpperCase() === 'ENDED' || currentState.toUpperCase() === 'UNAVAILABLE') {
			this.checkToOpenRateModal();
		} else {
			this.onShowFinishModal();
		}
	}

	checkToOpenRateModal = () => {
		const { interaction, appearance } = this.props;
		const { agent } = interaction;
		const { jsonAppearance = {} } = appearance;
		const { preferences = {} } = jsonAppearance;
		const { enableCustomerFeedback } = preferences;

		if (agent && enableCustomerFeedback) {
			this.onShowRateModal();
		} else {
			this.checkToOpenSendChatCopyModal();
		}
	}

	onSelectRate = (value) => {
		const { customerFeedback, interaction, customerFeedbackSkip } = this.props;
		const { interactionHash } = interaction;

		if (value) {
			customerFeedback(interactionHash, value);
		} else {
			customerFeedbackSkip();
		}

		this.onCloseRateModal();
		this.checkToOpenSendChatCopyModal();
	}

	checkToOpenSendChatCopyModal = () => {
		const { messages = [], appearance } = this.props;
		const { jsonAppearance = {} } = appearance;
		const { preferences = {} } = jsonAppearance;

		if (messages.length > 0 && preferences.enableTextTranscription) {
			this.onShowSendChatCopyModal();
		} else {
			this.onBackToHome();
		}
	}

	onShowRateModal = () => {
		this.setState({ showRateModal: true });
	}

	onCloseRateModal = () => {
		this.setState({ showRateModal: false });
	}

	onShowFinishModal = () => {
		this.setState({ showFinishModal: true });
	}

	onCloseFinishModal = () => {
		this.setState({ showFinishModal: false });
	}

	onShowSendChatCopyModal = () => {
		this.setState({ showSendChatCopyModal: true });
	}

	onCloseSendChatCopyModal = () => {
		this.setState({ showSendChatCopyModal: false });
	}

	onFinishInteraction = () => {
		const { finishInteraction } = this.props;

		finishInteraction();
		this.onCloseFinishModal();
		this.checkToOpenRateModal();
	}

	onBackToHome = () => {
		const {
			clearInteraction,
			clearMessages,
			history,
			interaction,
			onCloseVideo
		} = this.props;
		const { interactionHash } = interaction;

		clearInteraction();
		clearMessages();
		onCloseVideo(interactionHash);
		history.push('/');
	}

	render() {
		const {
			agent,
			onlineAgents,
			interaction,
			copy,
			toggleVideo,
			appearance,
			sendInteractionCopy
		} = this.props;
		const { showFinishModal, showRateModal, showSendChatCopyModal } = this.state;
		const {
			interactionHash,
			currentState,
			departmentId,
			customer = {}
		} = interaction;
		const { fields = {} } = customer;
		const { channel_email: channelEmail } = fields;
		const { averageService = 0 } = onlineAgents;
		const { agents = [] } = onlineAgents;
		const {
			jsonAppearance = {},
			listDepartmentWrapper = [],
			autostartWebcam = false
		} = appearance;
		const { preferences = {}, messages = {} } = jsonAppearance;
		const { ratingType = 'mood', showAverageService = true } = preferences;
		const { loading: copyLoading, success: copySuccess, error: copyError } = copy;
		const moodRatingOptions = [
			{ value: 'POSITIVE' },
			{ value: 'NEGATIVE' }
		];
		const starRatingOptions = [
			{ value: 'STAR_1' },
			{ value: 'STAR_2' },
			{ value: 'STAR_3' },
			{ value: 'STAR_4' },
			{ value: 'STAR_5' }
		];
		const npsRatingOptions = [
			{ value: 'NPS_0', label: '0' },
			{ value: 'NPS_1', label: '1' },
			{ value: 'NPS_2', label: '2' },
			{ value: 'NPS_3', label: '3' },
			{ value: 'NPS_4', label: '4' },
			{ value: 'NPS_5', label: '5' },
			{ value: 'NPS_6', label: '6' },
			{ value: 'NPS_7', label: '7' },
			{ value: 'NPS_8', label: '8' },
			{ value: 'NPS_9', label: '9' },
			{ value: 'NPS_10', label: '10' }
		];

		let ratingOptions = moodRatingOptions;
		if (ratingType === 'star') {
			ratingOptions = starRatingOptions;
		} else if (ratingType === 'nps') {
			ratingOptions = npsRatingOptions;
		}

		const getAvatar = () => {
			if (Object.keys(agent).length > 0 && currentState !== 'RINGING' && currentState !== 'ON_HOLD') {
				return (
					<>
						<AvatarName url={agent.photo || ''} name={agent.name} />
						<p className="ChatHeader__agent-name">
							{getFirstAndLastName(agent.name)}
						</p>
					</>
				);
			}

			return (
				<>
					<AvatarNameList agentList={agents} />
					{showAverageService && (
						<ResponseTime
							seconds={averageService}
							smallText
						/>
					)}
				</>
			);
		};

		return (
			<div className="ChatHeader">
				{showFinishModal && (
					<FinishModal
						onFinish={this.onFinishInteraction}
						onCancel={this.onCloseFinishModal}
					/>
				)}
				{showRateModal && (
					<RateModal
						rate={ratingType}
						onSelect={this.onSelectRate}
						agent={agent}
						options={ratingOptions}
					/>
				)}
				{showSendChatCopyModal && (
					<SendChatCopyModal
						description={typeof messages['message-end-text'] !== 'undefined' ? messages['message-end-text'] : ''}
						loading={copyLoading}
						success={copySuccess}
						error={copyError}
						customerEmail={channelEmail}
						onSend={(email) => sendInteractionCopy(interactionHash, email)}
						onClose={() => {
							this.onCloseSendChatCopyModal();
							this.onBackToHome();
						}}
					/>
				)}
				<div className="ChatHeader__avatar">
					{getAvatar()}
				</div>
				<div className="ChatHeader__video">
					{this.canEnableVideo({
						agent, currentState, listDepartmentWrapper, departmentId
					}) && !autostartWebcam && (
						<ButtonIcon
							svg={<ImgSvg name="video" />}
							click={toggleVideo}
						/>
					)}
				</div>
				{interactionHash && (
					<>
						<div className="ChatHeader__backIcon">
							<ButtonIcon
								fill="transparent"
								svg={<ImgSvg name="log-out" />}
								click={this.onBack}
							/>
						</div>
						{browserInfo().mobile && (
							<div className="ChatHeader__minimize">
								<Minimize />
							</div>
						)}
					</>
				)}
			</div>
		);
	}
}

ChatHeader.propTypes = {
	interaction: PropTypes.shape().isRequired,
	messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	copy: PropTypes.shape().isRequired,
	appearance: PropTypes.shape(),
	history: PropTypes.shape().isRequired,
	agent: PropTypes.shape(),
	onlineAgents: PropTypes.shape().isRequired,
	finishInteraction: PropTypes.func.isRequired,
	clearInteraction: PropTypes.func.isRequired,
	clearMessages: PropTypes.func.isRequired,
	customerFeedback: PropTypes.func.isRequired,
	customerFeedbackSkip: PropTypes.func.isRequired,
	toggleVideo: PropTypes.func.isRequired,
	onCloseVideo: PropTypes.func.isRequired,
	sendInteractionCopy: PropTypes.func.isRequired,
	showVideo: PropTypes.bool.isRequired
};

ChatHeader.defaultProps = {
	agent: {},
	appearance: {}
};

const mapStateToProps = (state) => ({
	interaction: state.interaction.data,
	messages: state.message.messages,
	copy: state.interaction.copy,
	agent: state.interaction.data ? state.interaction.data.agent : null,
	departmentId: state.interaction.data ? state.interaction.data.departmentId : null,
	onlineAgents: state.appearance.onlineAgents,
	appearance: state.appearance.data,
	showVideo: state.interface.showVideo
});

const mapActionsToProps = (dispatch) => ({
	finishInteraction: () => dispatch(actions.finishInteraction()),
	clearInteraction: () => dispatch(actions.clearInteraction()),
	clearMessages: () => dispatch(actions.clearMessages()),
	toggleVideo: () => dispatch(actions.toggleVideo()),
	customerFeedback: (hash, mood) => dispatch(actions.customerFeedback(hash, mood)),
	customerFeedbackSkip: () => dispatch(actions.customerFeedbackSkip()),
	onCloseVideo: (interactionHash) => dispatch(actions.closeVideo(interactionHash)),
	sendInteractionCopy: (interactionHash, email) => dispatch(
		actions.sendInteractionCopy(interactionHash, email)
	)
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ChatHeader));
