import React from 'react';
import PropTypes from 'prop-types';

import ButtonIcon from '../../Atoms/Buttons/ButtonIcon';
import ImgSvg from '../../Atoms/ImgSvg';
import './index.css';

const Send = ({ onSend }) => (
	<ButtonIcon className="Send" click={onSend} svg={<ImgSvg name="send-glyph" />} />
);

Send.propTypes = {
	onSend: PropTypes.func.isRequired
};

export default Send;
