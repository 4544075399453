import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const SingleChoice = ({ choices, onSelectChoice }) => {
	if (choices.length === 1) onSelectChoice(choices[0].value);
	return (
		<ul className="SingleChoice">
			{choices.map((choice) => {
				const { value, label } = choice;
				return (
					<li key={value} className="SingleChoice__choice">
						<input
							type="radio"
							name="value"
							id={value}
							className="SingleChoice__choice__input"
							onChange={() => onSelectChoice(value)}
							value={value}
						/>
						<label htmlFor={value} className="SingleChoice__choice__label">
							{label}
						</label>
					</li>
				);
			})}
		</ul>
	);
};

SingleChoice.propTypes = {
	choices: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string
	})),
	onSelectChoice: PropTypes.func
};

SingleChoice.defaultProps = {
	choices: [],
	onSelectChoice: () => {}
};

export default SingleChoice;
