import axios from 'axios';
import * as actionTypes from './actionTypes';

import { findGetParameter, getUrlWithProtocol } from '../../shared/utility';

export const fetchAppearanceStart = () => ({ type: actionTypes.FETCH_APPEARANCE_BEGIN });

export const fetchAppearanceSuccess = (appearanceInfo) => ({
	type: actionTypes.FETCH_APPEARANCE_SUCCESS,
	payload: appearanceInfo
});

export const fetchAppearanceFail = (error) => ({
	type: actionTypes.FETCH_APPEARANCE_FAIL,
	error
});

export const fetchAppearance = (accountId) => (dispatch) => {
	dispatch(fetchAppearanceStart());
	axios.get(`${getUrlWithProtocol('VISH')}/api/v1/appearance/${accountId}`).then((response) => {
		const appearanceInfo = {
			...response.data,
			jsonAppearance: JSON.parse(response.data.jsonAppearance)
		};

		const urlParams = window.omzVish.url.search;
		if (urlParams) {
			if (findGetParameter(urlParams, 'department_id')) {
				const urlDepartment = Number(findGetParameter(urlParams, 'department_id'));
				if (urlDepartment && urlDepartment.constructor === Number) {
					appearanceInfo.useUrlDepartment = true;
					appearanceInfo.urlDepartment = urlDepartment;
				}
			}

			if (findGetParameter(urlParams, 'has_customer') === 'true') {
				const urlCustomer = [];

				appearanceInfo.customerFields.forEach(({ name }) => {
					if (findGetParameter(urlParams, name)) {
						urlCustomer.push({ name, value: findGetParameter(urlParams, name) });
					}
				});
				appearanceInfo.useUrlCustomer = true;
				appearanceInfo.urlCustomer = urlCustomer;
				window.omzVish.updateCustomer({}, false);
			}
		}

		if (!findGetParameter(urlParams, 'skip_home')) {
			window.history.replaceState(null, null, window.location.pathname);
		}

		return dispatch(fetchAppearanceSuccess(appearanceInfo));
	}).catch((err) => dispatch(fetchAppearanceFail(err)));
};

export const fetchedOnlineAgentsSuccess = (data) => ({
	type: actionTypes.FETCHED_ONLINE_AGENTS_SUCCESS,
	payload: data
});
export const fetchedOnlineAgents = (data) => (dispatch) => {
	dispatch(fetchedOnlineAgentsSuccess(data));
};
