import React from 'react';
import PropTypes from 'prop-types';
import Bubble from '../../../Atoms/Bubble';
import './index.css';

const MessageAudio = ({ content, isFromAgent }) => {
	const { createdAt, url } = content;
	const classes = ['MessageAudio'];

	if (!isFromAgent) classes.push('MessageAudio--client');

	return (
		<span className={classes.join(' ')}>
			<Bubble isFromAgent={isFromAgent} time={createdAt}>
				<audio src={url} controls>
					<track kind="captions" />
				</audio>
			</Bubble>
		</span>
	);
};

MessageAudio.propTypes = {
	content: PropTypes.shape({
		url: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired
	}).isRequired,
	isFromAgent: PropTypes.bool.isRequired
};

export default MessageAudio;
